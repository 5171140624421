import "../../styles/CartSummary.css";
import { Link } from "react-router-dom";

function CartItem() {
  return (
    <div className="cart-small__item">
      <div className="cart-small__item__image"></div>
      <div className="cart-small__item__content">
        <div className="cart-small__item__content__description">
          <div className="cart-small__item__content__description__primary">
            JACK F4 Single Needle Lock Stitch Machine
          </div>
          <div className="cart-small__item__content__description__secondary">
            Remove from cart
          </div>
        </div>
        <div className="cart-small__item__content__summary">
          <div className="cart-small__item__content__summary__price">
            ₹20,600
          </div>

          <div className="cart-small__item__content__summary__quantity">
            <div className="minus">-</div>
            <div className="number">1</div>
            <div className="plus">+</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default function CartSummary({
  hideViewCart,
  buttonText = "CHECKOUT",
  includeShippingCosts = false,
}) {
  const items = [1, 2];
  return (
    <div className="cart-small">
      <div className="cart-small__items">
        {items.map((item) => (
          <CartItem />
        ))}
      </div>
      <div className="cart-small__totals">
        <div className="cart-small__totals__row">
          <div className="cart-small__totals__row__label">Subtotal</div>

          <div className="cart-small__totals__row__value">₹41,200</div>
        </div>

        <div className="cart-small__totals__row">
          <div className="cart-small__totals__row__label">GST</div>

          <div className="cart-small__totals__row__value">₹7,200</div>
        </div>

        {includeShippingCosts && (
          <div className="cart-small__totals__row">
            <div className="cart-small__totals__row__label">Shipping Cost</div>

            <div className="cart-small__totals__row__value">₹5,200</div>
          </div>
        )}

        <div className="cart-small__totals__row">
          <div className="cart-small__totals__row__label">Discount (10%)</div>

          <div className="cart-small__totals__row__value negative">-₹3,200</div>
        </div>

        <div className="cart-small__totals__row grand-total">
          <div className="cart-small__totals__row__label">Total</div>

          <div className="cart-small__totals__row__value">₹45,200</div>
        </div>
      </div>
      <div className="cart-small__options">
        <Link to="/checkout" className="cart-small__options__option button">
          {buttonText}
        </Link>
        {!hideViewCart && (
          <Link to="/cart" className="cart-small__options__option">
            Go To Cart
          </Link>
        )}
      </div>
    </div>
  );
}

import { Fragment } from "react";
import "../../styles/Associates.css";

function Associate({ image, primary, secondary }) {
  return (
    <div className="associate">
      <div className="image">
        <img src={image} />
      </div>
      <div className="primary">{primary}</div>
      <div className="secondary">{secondary}</div>
      <div className="link">Know More</div>
    </div>
  );
}

function Associates() {
  const associates = [
    {
      image: "/sewing.jpeg",
      primary: "Sewing Systems Pvt. Ltd",
      secondary:
        "Sewing systems is an established supplier for the past two decades of garment trims and accessories manufactured by world leaders for the apparel and leather industry.",
    },
    {
      image: "/orion.jpeg",
      primary: "Orion Trims",
      secondary:
        "Orion is a professionally managed company, with an emphasis on committed time bond delivery and high quality production culture of felts, shoulder pads and other accessories for suit manufacturing.",
    },
    {
      image: "/india.jpeg",
      primary: "India Agencies (Regd.)",
      secondary:
        "India Agencies (Regd.) is the leader in affordable apparel technology in India. IAR is the authorized distributor for Jack in India and other affordable brands to service the domestic and export garment industry.",
    },
    {
      image: "/waterwoods.jpeg",
      primary: "Waterwoods",
      secondary:
        "Established in 1999, Waterwoods was the first private lodge at Nagarhole National Park. Built in colonial style, we are a boutique wildlife lodge with an exclusive river front access in Kabini. Located between two famous tiger reserves.",
    },
  ];
  return (
    <Fragment>
      <div className="breadcrumbs">
        <div className="crumb">Home</div>
        <div className="seperator">{">"}</div>
        <div className="crumb">Associates</div>
      </div>

      <div className="associates-page">
        <div className="page-title">Our Associates</div>
        <div className="page-desc">
          We value the continued trust of our customers. We partner in their
          journey to success, ready to offer support and service. Channelizing
          the latest in the world of innovation, we provide the impetus for them
          to grow multifold. Our associates are part of our success and hold as
          another dimension to serve the industry.
        </div>

        <div className="items">
          {associates.map((i) => (
            <Associate
              image={i.image}
              primary={i.primary}
              secondary={i.secondary}
            />
          ))}
        </div>
      </div>
    </Fragment>
  );
}

export default Associates;

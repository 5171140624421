import { Fragment } from "react";
import "../../styles/Company.css";
import { isMobile } from "react-device-detect";

function Company() {
  return (
    <Fragment>
      <div className="breadcrumbs">
        <div className="crumb">Home</div>
        <div className="seperator">{">"}</div>
        <div className="crumb">Company</div>
      </div>
      <div className="company-page">
        <div className="left-column">
          <div className="section-title">Who we are</div>
          <div className="section">
            <div
              className="paragraph"
              style={{
                gridColumnStart: "1",
                gridColumnEnd: isMobile ? "13" : "8",
                paddingRight: "16px",
                marginTop: "24px",
              }}
            >
              With a network of 45 world leaders in home textiles and apparel
              technology spread across many continents, 15 modern offices across
              India employing over 500 professionals, and over 25,000 satisfied
              customers, all in a span of over 3 decades, speaks volumes about
              the success and growth record of India's foremost technology and
              services provider, IIGM.
            </div>

            <div
              className="paragraph"
              style={{
                gridColumnStart: "1",
                gridColumnEnd: isMobile ? "13" : "8",
                paddingRight: "16px",
                marginTop: "24px",
              }}
            >
              We use technology in every facet of our business by using SAP,
              CRM, Apps and Webinars to enhance deliverables and satisfy user
              experience.
            </div>

            <div
              className="paragraph"
              style={{
                gridColumnStart: "1",
                gridColumnEnd: isMobile ? "13" : "8",
                paddingRight: "16px",
                marginTop: "24px",
              }}
            >
              Our core team manages each vertical with a dedicated group of
              sales, application and service professionals. IIGM has the ability
              to service clientele across the spectrum from small companies to
              the largest groups across India, Middle East and parts of
              South-East Asia.
            </div>
          </div>
          <div className="image-row">
            <div
              className="img-container"
              style={{ height: "134px", marginTop: "24px" }}
            >
              <img src="/company3.jpeg" style={{ height: "100%" }} />
            </div>

            <div
              className="img-container"
              style={{ height: "134px", marginTop: "24px" }}
            >
              <img src="/company1.jpeg" style={{ height: "100%" }} />
            </div>

            <div
              className="img-container"
              style={{ height: "134px", marginTop: "24px" }}
            >
              <img src="/company2.jpeg" style={{ height: "100%" }} />
            </div>

            <div
              className="img-container"
              style={{ height: "134px", marginTop: "24px" }}
            >
              <img src="/company4.jpeg" style={{ height: "100%" }} />
            </div>
          </div>
          {/*<div
          className="image-container"
          style={{ height: "324px", overflow: "hidden" }}
        >
          <img src="/company2.jpeg" style={{ width: "100%" }} />
        </div>*/}

          <div className="section-title">IIGM training centre</div>
          <div className="section">
            <div
              className="paragraph"
              style={{
                gridColumnStart: "1",
                gridColumnEnd: isMobile ? "13" : "8",
                paddingRight: "16px",
                marginTop: "24px",
              }}
            >
              Training has always been an integral part of our corporate
              culture. In house training programmes, customised programmes for
              customers and individual training for young entrants to the
              industry are offered in all the main cities of India. IIGM
              training centers are well know for offering full time in-house
              programmes for machine maintenance, CAD, embroidery applications,
              productivity and quality areas, training of trainer programmes for
              skill development, and the evaluation of technical workforces in
              organisations. IIGM’s training programmes have received
              accreditation from world renowned companies such as JUKI, GERBER,
              WILCOM, BARUDAN, EASTMAN, NGAI SHING, HO HSING, JACK and many
              more.
            </div>
          </div>
          <div className="image-row">
            <div
              className="img-container"
              style={{ height: "134px", marginTop: "24px" }}
            >
              <img src="/training-centre1.jpeg" style={{ height: "100%" }} />
            </div>

            <div
              className="img-container"
              style={{ height: "134px", marginTop: "24px" }}
            >
              <img src="/training-centre2.jpeg" style={{ height: "100%" }} />
            </div>

            <div
              className="img-container"
              style={{ height: "134px", marginTop: "24px" }}
            >
              <img src="/training-centre3.jpeg" style={{ height: "100%" }} />
            </div>

            <div
              className="img-container"
              style={{ height: "134px", marginTop: "24px" }}
            >
              <img src="/training-centre4.jpeg" style={{ height: "100%" }} />
            </div>
          </div>
          <div className="section-title">{"Supply Chain & Logistics"}</div>
          <div className="section">
            <div
              className="paragraph"
              style={{
                gridColumnStart: "1",
                gridColumnEnd: isMobile ? "13" : "8",
                paddingRight: "16px",
                marginTop: "24px",
              }}
            >
              We have created abilities to provide our products through direct
              shipments from our overseas principles, bonded warehouses and duty
              paid goods through our various branches in India. All our
              international products are made available and can be delivered
              seamlessly within 24 hours. We stock over 18,000 machines at any
              given point of time. These products can also be delivered to our
              overseas customers through our customs bonded warehouses. We can
              also provide door-to-door delivery services in any part of the
              country. IIGM also stocks over 180,000 SKU’s of parts supply for
              all the different types of machines that we market world over. We
              are committed to supplying reliable after market products to
              ensure that machine have the least amount of down time. This is
              managed through a complex SAP software for quick search and
              delivery.
            </div>
          </div>
          <div className="image-row">
            <div
              className="img-container"
              style={{ height: "134px", marginTop: "24px" }}
            >
              <img src="/supply-chain1.jpeg" style={{ height: "100%" }} />
            </div>

            <div
              className="img-container"
              style={{ height: "134px", marginTop: "24px" }}
            >
              <img src="/supply-chain3.jpeg" style={{ height: "100%" }} />
            </div>

            <div
              className="img-container"
              style={{ height: "134px", marginTop: "24px" }}
            >
              <img src="/supply-chain4.jpeg" style={{ height: "100%" }} />
            </div>

            <div
              className="img-container"
              style={{ height: "134px", marginTop: "24px" }}
            >
              <img src="/supply-chain6.jpeg" style={{ height: "100%" }} />
            </div>
          </div>
        </div>
        {!isMobile && (
          <div className="right-column">
            <div
              className="img-container"
              style={{
                height: "40px",
                justifyContent: "flex-end",
                borderRadius: 0,
              }}
            >
              {/*<div className="fill" />*/}
              <img src="/iigm-logo.png" style={{ height: "100%" }} />
            </div>

            <div
              className="img-container"
              style={{ height: "496px", marginTop: "16px" }}
            >
              <img src="/company6.jpeg" style={{ height: "100%" }} />
            </div>
          </div>
        )}
      </div>
    </Fragment>
  );
}

export default Company;

import { Fragment } from "react";
import "../../styles/Management.css";
import { isMobile } from "react-device-detect";

function Person({ img, primary, secondary, desc }) {
  return (
    <div className="management-item">
      <div className="image">
        <img src={img} />
      </div>
      <div className="primary-text">{primary}</div>
      <div className="secondary-text">{secondary}</div>
      <div className="desc">{desc}</div>
    </div>
  );
}

function Management() {
  const people = [
    {
      img: "/Raghav-Sharma.jpeg",
      primary: "Raghav Sharma",
      secondary: "Director",
      desc: "They call him 'whisker' for a reason. With a Business Management Degree from Delhi University and ample street smartness, he is the first point of contact for most. He believes that the attitude of an individual is every thing for commercial success in this day and age.",
    },
    {
      img: "/Arjun-Kapoor.jpeg",
      primary: "Arjun Kapoor",
      secondary: "Director",
      desc: "With a Finance and Economics degree from Pepperdine University and a passion for electrical technology, he is the source of knowledge in the electrical busbar space. Arjun holds a deep passion for nature and developes the real estate holdings of the company each day.",
    },
    {
      img: "/Kartik-Kapoor.jpeg",
      primary: "Kartik Kapoor",
      secondary: "Project Manager",
      desc: "Graduated from the University of Technology, Sydney with a bachelor of business and marketing. He brings to the the table a set a unique skills to develop existing and new age products for the company. A vision for 'Asset Care and Services' is part of his destiny.",
    },
    {
      img: "/Kitty-Kapoor.jpeg",
      primary: "Kitty Kapoor",
      secondary: "Head of Accounts & Finance",
      desc: "Her sole role is to manage accounts of all primary businesses. With a back ground in chartered accountancy and accounting skills from Leeds University, she is what you need when your accounts are outstanding. Her contribution to the company has been excepetional due to her diligence in SAP and analytical skills.",
    },
    {
      img: "/Tania.jpeg",
      primary: "Tania Raghav Sharma",
      secondary: "Head of Marketing & Sales",
      desc: "She is known to push her way through any given space and her attitude is very much ingrained into our corporate culture. With a degree in Business and her skills for selling software, her role is profitability. She is also at the forefront of all marketing activities in the north of India to align the company's true vision.",
    },
    {
      img: "/Bela-Sharma.jpeg",
      primary: "Bela Sharma",
      secondary: "Head of Spares & After Market",
      desc: "Being a mother of two, never stopped her passion for spares and service. She promotes ethical means of asset care to all customers. Sourcing what you need and when you need it, is her unique measure to keep factories running. Her formula has revolutionised the garment industry over the past two decades.",
    },
    {
      img: "/Ved.jpeg",
      primary: "Ved Prakash",
      secondary: "Chief Financial Officer",
      desc: "They say, he will live a thousand years. His immense skill for accounting and finance has allowed IIGM to grow into an ocean so far speard, the human eye can't see. A loyal image in the eyes of all stake holders, Mr. Ved is the back bone of this organisation.",
    },
    {
      img: "/Sajith-Kumar.jpeg",
      primary: "Sajith Kumar",
      secondary: "Vice President, Automation Solutions",
      desc: "A mechanical engineer at heart with a vision in soft services, he is a true visionary for the brand. He has sold more automatic cutters in the garment industry than anyone on the face of this planet. Two decades of providing CAD and CAM solutions has earned him a reputable status in the industry world over. Mr.Sajith truly believes in customer service as the fore most rule in setting high expectations and achieving them consistently.",
    },
    {
      img: "/Pankaj.jpeg",
      primary: "Pankaj Chaudry",
      secondary: "Vice President, ISM",
      desc: "His role of empowerment within the company is the key to success for his extraordinary services in the garment industry. With a post graduate degree from LSW and a management degree from IIT Delhi, he has proved to be the guiding force for many of his team mates in the company. He belives that goals are meant to be achived and is possible only by hard work and determination.",
    },
    {
      img: "/Sudhir.jpeg",
      primary: "Sudhir Sharma",
      secondary: "Vice President, Service",
      desc: "'Asset Care' is our vision from the very start, which has been developed by Mr. Sudhir, himself. Uncompromised hard work and dedication has allowed this organisation to sustain itself in the eyes of our customers. His contribution is invaluable in any form and he continues to reinvent means of reducing down times in garment factories.",
    },
    {
      img: "/Shilesh.jpeg",
      primary: "Shailesh",
      secondary: "Vice President - Wilcom Software",
      desc: "A hardworking and innovative leader with a First Class Honours Degree in Electronics from VJTI. He grew up in the soul of Mumbai, rapidly growing to legalise pirated embroidery software across the world. His commitment for education towards legalisation is as strong as can be. With a strong position in IIGM, he strives to make software more affordable and accepted each day.",
    },
    {
      img: "/Gopalkrishna.jpeg",
      primary: "Gopala Krishna",
      secondary: "Vice President, Spares & After Market",
      desc: "Ask him for any part of any machine on the surface of this blessed planet and he will source it for you. An envied persona that has stood threw the ups and downs of a lifetime. He represents the deep supply chain formulas that make this company a success. If your up late, working till the sun comes up, he will be able to show you a way out of it.",
    },
    {
      img: "/Azeez.jpeg",
      primary: "Azeez",
      secondary: "Executive Director, IAR",
      desc: "Brought up in the middle east, but shares all the resemblances of a true high spirited Indian. He individually controls all areas associated with affordable technology and caters to the ever expanding domestic market in India. He measures his success by performance figures and nothing less. A true leader that is ever present at his workplace to show you the way.",
    },
    {
      img: "/Sathya.jpeg",
      primary: "Satyadev Gopal",
      secondary: "Vice President, Ludhiana",
      desc: "An MBA graduate with years of experience in the garment industry. Since 1997, he has gradually reached the position of a leader in the Ludhiana apparel market. His passion for technology upgradation has led the industry to eat from his very own hands. A master of customer satisfaction and a drive of loyalty for his customers keep taking him to several hights.",
    },
    {
      img: "/Ram.jpeg",
      primary: "Ram",
      secondary: "Vice President, Chennai",
      desc: "The only thing right in life is to follow the imbedded path. A visionary from Tamil Nadu, he is the quintessential tough guy that handles export and domestic markets with ease. Grown up from ground up, he has been the back bone of our founder for many years and continuous to be the leader in many individuals eyes. Mr. Ram stands by the saying, 'work is worship'.",
    },
    {
      img: "/Ganga.jpeg",
      primary: "Gangadhara Rao",
      secondary: "General Manager, Electrical Division",
      desc: "His shrudness in sport is comparable to his smarts in electrifying commercial and industrial buildings. With multiply degrees in electrical engineering, he is pursuing a PH.D., while he contributes to the organisation. Born from the bottom, this man has fought every opponent head on and continues to with great hindsight and vision.",
    },
  ];
  return (
    <Fragment>
      <div className="breadcrumbs">
        <div className="crumb">Home</div>
        <div className="seperator">{">"}</div>
        <div className="crumb">Management</div>
      </div>
      <div className="management-page">
        {isMobile && (
          <Fragment>
            <div className="page-title">Management</div>
            <div className="page-desc" style={{ marginBottom: "32px" }}>
              A futuristic vision backed by years of experience, we are led by a
              team of aligned professionals. With a focus on uncompromised
              quality solutions and service, we have set high standards of
              excellence.
            </div>
          </Fragment>
        )}
        <div className="left-column">
          <div className="highlight">
            <Person
              img={"/Prem-Khanna.jpeg"}
              primary={"Prem Khanna"}
              secondary={"Late Chairman"}
              desc={
                "Decades of success and passion for technology makes him the god father of the industry. Mr. Khanna is associated with many other organisations in India and holds a renowned reputation in the textile and garment space. His vision for distribution and service support has allowed IIGM to expand its wings in the North of India. Age is not a factor for him as he continues to contribute each day and guide his fellow squad members."
              }
            />
            <Person
              img={"/Pavan-Kapoor.jpeg"}
              primary={"Pavan Kapoor"}
              secondary={"Managing Director"}
              desc={
                "They say that his record is so consistent, 'he never missed a game of golf, nor did he miss making a success out of a product.' With a Management degree from IIM and years of success in the garment tech space, an enviable persona of the industry that has continuously innovated for the future. His vision is to give people the freedom they deserve each day, allowing them to be creative and show their true potential."
              }
            />
          </div>
        </div>

        <div className="right-column">
          {!isMobile && (
            <Fragment>
              <div className="page-title">Management</div>
              <div className="page-desc">
                A futuristic vision backed by years of experience, we are led by
                a team of aligned professionals. With a focus on uncompromised
                quality solutions and service, we have set high standards of
                excellence.
              </div>
            </Fragment>
          )}

          <div className="people">
            {people.map((p) => (
              <div className="person-wrapper">
                <Person
                  img={p.img}
                  primary={p.primary}
                  secondary={p.secondary}
                  desc={p.desc}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default Management;

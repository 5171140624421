import { Fragment, useRef, useState } from "react";
import "./App.css";
import { isMobile } from "react-device-detect";
import { ReactComponent as CartIcon } from "./icons/cart.svg";
import { ReactComponent as HeartIcon } from "./icons/heart.svg";
import { ReactComponent as UserIcon } from "./icons/user.svg";
import { ReactComponent as SearchIcon } from "./icons/search.svg";
import { ReactComponent as MenuIcon } from "./icons/menu.svg";
import { ReactComponent as QuoteIcon } from "./icons/add-to-quote.svg";
import { Switch, Route, Link } from "react-router-dom";
import {
  Home,
  Products,
  Product,
  SpareParts,
  Brands,
  Services,
  About,
  Cart,
  Checkout,
} from "./screens";
import Dropdown from "./components/common/Dropdown";
import { ReactComponent as DownIcon } from "./icons/caret-down.svg";
import { useAppState } from "./utils/appState";
import CartSummary from "./components/common/CartSummary";
import ClickOutside from "./components/common/ClickOutside";
// import ScrollToTop from "./components/common/ScrollToTop";

function App() {
  // const [{ scrollHandler }] = useAppState();
  const mediaRef = useRef(null);
  const scrollEndRef = useRef(null);
  const imageRef = useRef(null);
  const galleryRef = useRef(null);
  const [mediaMargin, setMediaMargin] = useState(0);
  const [galleryHeight, setGalleryHeight] = useState(600);
  const [bgScale, setBgScale] = useState(1);
  const [{ modal }, setAppState] = useAppState();

  const handleScroll = (e) => {
    // console.log("Handling scroll");
    if (isMobile) return;
    // console.log("viewport height:", window.innerHeight);
    const viewportHeight = window.innerHeight;

    if (!mediaRef.current || !scrollEndRef.current || !imageRef.current) return;

    const offsetStart = mediaRef?.current.offsetTop;
    const offsetEnd =
      scrollEndRef.current.offsetTop - imageRef.current.clientHeight - 64;

    if (e.target.scrollTop < offsetStart) {
      setMediaMargin(0);
      // console.log("Case 1");
      setGalleryHeight(viewportHeight + e.target.scrollTop);
    } else if (e.target.scrollTop > offsetEnd) {
      setMediaMargin(offsetEnd - offsetStart);
      // console.log("Case 2");
      // const newBgMargin = ((e.target.scrollTop - offsetEnd) / offsetEnd) * -100;
      // const newBgMargin = (e.target.scrollTop - offsetEnd) / offsetEnd;
      // console.log("NBM:", newBgMargin);
      // setBgMargin(newBgMargin);
      setGalleryHeight(
        Math.max(scrollEndRef.current.offsetTop - e.target.scrollTop + 64, 0)
      );

      // console.log(
      //   "Case 2 height:",
      //   scrollEndRef.current.offsetTop - e.target.scrollTop
      // );
    } else {
      setMediaMargin(e.target.scrollTop - mediaRef.current.offsetTop);
      // console.log("Case 3");
      setGalleryHeight(viewportHeight);
    }
    //Start scaling when below turns positive
    const startScaling = scrollEndRef.current.offsetTop - e.target.offsetHeight;
    const endScaling = startScaling + e.target.offsetHeight;
    const start = e.target.scrollTop - startScaling > 0;
    const end = e.target.scrollTop - endScaling > 0;
    // const scalingPercentage=scrollTop-startScaling
    // console.log(e.target.scrollTop);
    // console.log("Start:", e.target.scrollTop - startScaling > 0);
    // console.log("End", e.target.scrollTop - endScaling > 0);
    // console.log(
    //   "Diff:",
    //   e.target.scrollTop - startScaling - e.target.offsetHeight
    // );
    const diff = e.target.scrollTop - startScaling - e.target.offsetHeight;
    const newBgScale =
      8 - 7 * ((e.target.offsetHeight + diff) / e.target.offsetHeight);
    // console.log(bgScale);
    if (start && !end) {
      setBgScale(newBgScale);
    }

    // setBgMargin(
    //   1.5 - 0.5 * ((e.target.offsetHeight + diff) / e.target.offsetHeight)
    // );
    // console.log(
    //   e.target.scrollTop -
    //     scrollEndRef.current.offsetTop +
    //     e.target.offsetHeight
    // );
    // console.log(scroll);
    // console.log(e.target.offsetHeight);
  };

  return (
    <Fragment>
      <div
        className={"App " + (!!modal ? "modal-open" : "")}
        onScroll={handleScroll}
      >
        <div className="header">
          <div className="secondary-header">
            <div className="link">Store Locator</div>
            <div className="seperator">|</div>
            <div className="link">Sign In Or Create An Account</div>
          </div>
          <div className="primary-header">
            <Link to="/" className="logo">
              <img src="/iigm-logo.png" />
            </Link>

            {isMobile && (
              <div className="options">
                <div className="option-button">
                  <div className="icon">
                    <QuoteIcon style={{ height: 20 }} />
                  </div>
                </div>
                <Link to="/cart" className="option-button">
                  <div className="icon">
                    <CartIcon style={{ fill: "#f60", height: 20 }} />
                  </div>
                </Link>
              </div>
            )}

            {!isMobile && (
              <div className="options">
                <div className="search">
                  <input
                    type="text"
                    placeholder="Search by name, brand, category or keywords"
                  />
                  <div className="submit-button">
                    <SearchIcon style={{ height: 20 }} />
                  </div>
                </div>
                <div className="option-button">
                  <div className="icon">
                    <QuoteIcon style={{ height: 20 }} />
                  </div>
                  <div className="text">
                    <div className="primary">Quote (0)</div>
                  </div>
                </div>
                {/* <div className="option-button">
                <div className="icon">
                  <CartIcon style={{ fill: "#f60", height: 20 }} />
                </div>
                <div className="text">
                  <div className="primary">Cart (0)</div>
                </div>
              </div> */}

                <Dropdown
                  target={
                    <div className="option-button">
                      <div className="icon">
                        <CartIcon style={{ fill: "#f60", height: 20 }} />
                      </div>
                      <div className="text">
                        <div className="primary">Cart (0)</div>
                      </div>
                    </div>
                  }
                  menu={<CartSummary />}
                  placement={"bottom-end"}
                />
                <div className="primary-button">
                  <UserIcon style={{ marginRight: 8 }} />
                  LOGIN
                </div>
              </div>
            )}
          </div>
          <div className="nav">
            {isMobile && (
              <div className="nav-menu-button">
                <MenuIcon />
              </div>
            )}
            {!isMobile && (
              <Fragment>
                <Link to="/" className="nav-item">
                  HOME
                </Link>
                <Link to="/products" className="nav-item">
                  PRODUCTS
                </Link>
                <Link to="/spare-parts" className="nav-item">
                  SPARE PARTS
                </Link>
                <Link to="/brands" className="nav-item">
                  BRANDS
                </Link>
                <Link to="/services" className="nav-item">
                  SERVICES
                </Link>
                {/*<Link to="/about" className="nav-item">
                ABOUT US
          </Link>*/}
                <Dropdown
                  target={
                    <Link to="/about" className="nav-item">
                      ABOUT US{" "}
                      <DownIcon
                        style={{
                          fill: "white",
                          marginLeft: "4px",
                          height: "10px",
                        }}
                      />
                    </Link>
                  }
                  menu={
                    <div className="nav-menu">
                      <Link to={"/about/company"} className="nav-menu-item">
                        Company
                      </Link>
                      <Link to={"/about/management"} className="nav-menu-item">
                        Management
                      </Link>
                      <Link to={"/about/branches"} className="nav-menu-item">
                        Branches
                      </Link>
                      <Link to={"/about/associates"} className="nav-menu-item">
                        Associates
                      </Link>
                      <Link
                        to={"/about/achievements"}
                        className="nav-menu-item"
                      >
                        Achievements
                      </Link>
                      <Link to={"/about/careers"} className="nav-menu-item">
                        Careers
                      </Link>
                    </div>
                  }
                  placement={"bottom-start"}
                />
              </Fragment>
            )}
          </div>
        </div>

        <div className="content">
          {/* <ScrollToTop /> */}
          <Switch>
            <Route path="/home">
              <Home />
            </Route>
            <Route path="/products">
              <Products />
            </Route>
            <Route path="/product/:id">
              <Product
                mediaRef={mediaRef}
                imageRef={imageRef}
                galleryRef={galleryRef}
                scrollEndRef={scrollEndRef}
                mediaMargin={mediaMargin}
                galleryHeight={galleryHeight}
                bgScale={bgScale}
              />
            </Route>
            <Route path="/spare-parts">
              <SpareParts />
            </Route>
            <Route path="/brands">
              <Brands />
            </Route>
            <Route path="/services">
              <Services />
            </Route>
            <Route path="/about">
              <About />
            </Route>
            <Route path="/cart">
              <Cart />
            </Route>
            <Route path="/checkout">
              <Checkout />
            </Route>
            <Route path="/">
              <Home />
            </Route>
          </Switch>
        </div>
        <div className="footer"></div>
      </div>
      <div className={"app-modal " + (!!modal ? "open" : "")}>
        <ClickOutside
          onClickOutside={() => {
            setAppState({ modal: false });
          }}
        >
          {(wrapperRef) => (
            <div className="app-modal-container" ref={wrapperRef}>
              {modal}
            </div>
          )}
        </ClickOutside>
      </div>
    </Fragment>
  );
}

export default App;

import { Fragment } from "react";
import "../../styles/Careers.css";

function Careers() {
  return (
    <Fragment>
      <div className="breadcrumbs">
        <div className="crumb">Home</div>
        <div className="seperator">{">"}</div>
        <div className="crumb">Careers</div>
      </div>

      <div className="careers-page">
        <div className="left-column">
          <div className="page-title">Careers @ IIGM</div>
          <div className="page-desc">
            At IIGM we solve complex problems in the name of our core mission to
            bring the world's latest technology at your doorstep and provide
            immaculate service. But what makes working at IIGM truly unique is
            the work place culture that encourages innovation and a healthy
            decision making ability. Our enviable founder, Mr. Pavan Kapoor has
            given this company a secret ingredient that has made IIGM a great
            place to work.
          </div>

          <div className="page-desc">
            In case you do not find any suitable current opening, you may then
            submit your resume at <span className="bold">jobs@iigm.in</span>
          </div>
        </div>
        <div className="right-column">
          <img src={"/careers-banner.jpeg"} />
        </div>
      </div>
    </Fragment>
  );
}

export default Careers;

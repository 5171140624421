import "../styles/About.css";
import {
  Company,
  Careers,
  Management,
  Branches,
  Associates,
  Achievements,
} from "../components/about";
import { Switch, Route } from "react-router-dom";

function About() {
  return (
    <div className="about">
      <Switch>
        <Route path="/about/company">
          <Company />
        </Route>
        <Route path="/about/Careers">
          <Careers />
        </Route>
        <Route path="/about/management">
          <Management />
        </Route>
        <Route path="/about/branches">
          <Branches />
        </Route>
        <Route path="/about/associates">
          <Associates />
        </Route>
        <Route path="/about/achievements">
          <Achievements />
        </Route>
        <Route path="/about">
          <Company />
        </Route>
      </Switch>
    </div>
  );
}

export default About;

import "../styles/Cart.css";
import { ReactComponent as ShippingIcon } from "../icons/shipping.svg";
import { ReactComponent as CartIcon } from "../icons/cart.svg";
import { ReactComponent as AvailabilityIcon } from "../icons/availability.svg";
import { Link } from "react-router-dom";
import CartSummary from "../components/common/CartSummary";
import { isMobile } from "react-device-detect";

function CartItem() {
  return (
    <div className="cart-item">
      <div className="cart-item__image"></div>
      <div className="cart-item__content">
        <div className="cart-item__content__primary">
          JACK F4 Single Needle Lock Stitch Machine
        </div>
        <div className="cart-item__content__secondary">
          <AvailabilityIcon
            style={{ height: 14, marginLeft: "-5px", marginRight: "4px" }}
          />{" "}
          <span className="green"> In Stock</span>
        </div>

        <div className="cart-item__content__secondary">
          <ShippingIcon
            style={{ height: 14, marginLeft: "-4px", marginRight: "4px" }}
          />{" "}
          Delivery in 4 - 6 Days
        </div>

        <div className="cart-item__content__options">
          <span className="cart-item__content__options__option">
            Remove from cart
          </span>
          |
          <span className="cart-item__content__options__option">
            Save for later
          </span>
          |
          <span className="cart-item__content__options__option">
            See related products
          </span>
        </div>
      </div>
      <div className="cart-item__price">
        <div className="cart-item__price__value">₹20,600</div>

        <div className="cart-item__price__quantity">
          <div className="minus">-</div>
          <div className="number">1</div>
          <div className="plus">+</div>
        </div>
      </div>
    </div>
  );
}

export default function Cart() {
  const items = [1, 2];
  return (
    <div className="cart-page">
      <div className="cart-page__left-container">
        <div className="cart-page__shopping-cart">
          <div className="cart-page__shopping-cart__title">
            <CartIcon style={{ fill: "#f60", height: 20, marginRight: 16 }} />
            YOUR CART
          </div>
          {isMobile ? (
            <CartSummary hideViewCart={true} />
          ) : (
            <div className="cart-page__shopping-cart__body">
              {items.map((item) => (
                <CartItem />
              ))}
            </div>
          )}

          {!isMobile && (
            <div className="cart-page__shopping-cart__footer">
              Subtotal <span className="subtotal">₹41,200</span>
            </div>
          )}
        </div>
      </div>
      {!isMobile && (
        <div className="cart-page__right-container">
          <div className="cart-page__order-summary">
            <div className="cart-page__order-summary__title">ORDER SUMMARY</div>
            <div className="cart-page__order-summary__totals">
              <div className="cart-page__order-summary__totals__row">
                <div className="cart-page__order-summary__totals__row__label">
                  Subtotal
                </div>
                <div className="cart-page__order-summary__totals__row__value">
                  ₹20,600
                </div>
              </div>

              <div className="cart-page__order-summary__totals__row">
                <div className="cart-page__order-summary__totals__row__label">
                  GST
                </div>
                <div className="cart-page__order-summary__totals__row__value">
                  ₹7,200
                </div>
              </div>

              <div className="cart-page__order-summary__totals__row">
                <div className="cart-page__order-summary__totals__row__label">
                  Discount (10%)
                </div>
                <div className="cart-page__order-summary__totals__row__value negative">
                  -₹3,200
                </div>
              </div>

              <div className="cart-page__order-summary__totals__row grand-total">
                <div className="cart-page__order-summary__totals__row__label">
                  Total
                </div>
                <div className="cart-page__order-summary__totals__row__value">
                  ₹27,800
                </div>
              </div>
            </div>
            <Link
              to="/checkout"
              className="cart-page__order-summary__checkout-button"
            >
              PROCEED TO CHECKOUT
            </Link>
          </div>
        </div>
      )}
    </div>
  );
}

import { Fragment } from "react";
import "../../styles/Branches.css";
import { ReactComponent as CallIcon } from "../../icons/call.svg";
import { ReactComponent as MailIcon } from "../../icons/mail.svg";
import { ReactComponent as ContactIcon } from "../../icons/contact.svg";

function Branch({ city, address, contact }) {
  return (
    <div className="branch">
      <div className="city">{city}</div>
      <div className="address">
        {address.map((l) => (
          <div className="line">{l}</div>
        ))}
      </div>
      <div className="contact">
        <div className="name contact-item">
          <div className="icon">
            <ContactIcon style={{ fill: "#f60" }} />
          </div>
          {contact.name}
        </div>
        {contact.phone && (
          <div className="phone contact-item">
            <div className="icon">
              <CallIcon style={{ fill: "#f60" }} />
            </div>
            {contact.phone}
          </div>
        )}

        {contact.email && (
          <div className="email contact-item">
            <div className="icon">
              <MailIcon style={{ fill: "#f60" }} />
            </div>
            {contact.email}
          </div>
        )}
      </div>
    </div>
  );
}

function Branches() {
  const branches = {
    india: [
      {
        city: "Bangalore",
        address: [
          "IIGM Private Limited",
          "Springdale, No. 51",
          "Residency Road, 3rd Cross",
          "Bangalore - 560 025",
          "Tel: +91 80 4193 3600",
          "Fax: +91 80 2532 0899",
        ],
        contact: {
          name: "Mrs. Malar Vizhi",
          email: "adminblr@iigm.in",
          phone: "+91 9773946947",
        },
      },
      {
        city: "Cochin",
        address: [
          "IIGM Private Limited",
          "Door No: 11/214 & 215",
          "Ground Floor, Thannipilly Tower",
          "Kuttikkattukara PO",
          "Eloor - 683501",
          "Tel: 0484-2545406",
        ],
        contact: {
          name: "Mr. Manoj Jose",
          email: "manoj.jose@iigm.in",
          phone: "08589097771",
        },
      },
      {
        city: "Chennai",
        address: [
          "IIGM Private Limited",
          "“Dugar Estate”, #89, C.T.H Road,",
          "Next to T.I. Diamond Chain,",
          "Ambattur, Chennai - 600 053",
          "Tel : +91 44 49033400 (Extn:444)",
          "Direct : +91 44 49033444",
          "Fax : +91 44 49033499",
        ],
        contact: {
          name: "Mr. Sugananth.P",
          email: "sugananth@iigm.in",
          phone: "+91 7338899366",
        },
      },
      {
        city: "Tiripur",
        address: [
          "IIGM Private Limited",
          "43/18, 4th Street,",
          "Avinashi Road, Gandhi Nagar",
          "Tirupur-641 603.",
          "Tel : +91 421 4304446",
          "Fax : 0091-421-4304475",
        ],
        contact: {
          name: "Mr. Baskar",
          email: "baskar@iigm.in / kartik@iigm.in",
          phone: "+91-9361109683",
        },
      },
      {
        city: "Kolkata",
        address: [
          "IIGM Private Limited",
          "Unit No.401B, 4th Floor,Tower-2,",
          "P.S Srijan Corporate Park, Plot No. G-2",
          "Block-GP, Sector-V, Bidhan Nagar",
          "Salt Lake, Kolkata-700091",
          "Tel : 033-40084790 / 46022062",
          "Tel : 033-46013757",
        ],
        contact: {
          name: "Mr. Somnath Bhaduri",
          email: "somnath@iigm.in",
          phone: "8229945125",
        },
      },
      {
        city: "Hyderabad",
        address: [
          "IIGM Private Limited",
          "Shop no . 12 G, Jade Arcade",
          "Near Paradise Circle,",
          "Behind Dadus Mittai Vatika, M.G. Road",
          "Secunderabad-500003.",
          "Tel : +914040037307",
        ],
        contact: {
          name: "Mr. Ashish Kumar",
          email: "iigmhyd@iigm.in",
          phone: "9341264247",
        },
      },
      {
        city: "Madurai",
        address: [
          "IIGM Private Limited",
          "Plot D-3/1,",
          "Kappalur Industrial Estate,",
          "Tirumangalam Taluk,",
          "Madurai - 625008",
          "Tel : +91 452 2488656",
        ],
        contact: {
          name: "Mr.Suresh Babu",
          email: "suresh@iigm.in",
          phone: "9381066579",
        },
      },
      {
        city: "Mumbai",
        address: [
          "IIGM Private Limited",
          "903-904, Damji Shamji Business Galleria,",
          "Next to Huma Mall,",
          "L.B.S Marg,",
          "Kanjur Marg(W),",
          "Mumbai - 400 078",
          "Tel : +91 22 67836700",
        ],
        contact: {
          name: "Mr. Shailesh Dhabi",
          email: "shailesh@iigm.in",
          phone: "9324804047",
        },
      },
      {
        city: "Jaipur",

        address: [
          "IIGM Private Limited",
          "#53/194, VT Road,",
          "Mansarovar,",
          "Jaipur - 302019.",
          "Tel : +91 141 4108308",
        ],
        contact: { name: "Mr. Sudeep Sharma", email: "", phone: "9314508308" },
      },
      {
        city: "Delhi",

        address: [
          "IIGM Private Limited",
          "B-112, Okhla Industrial Area,",
          "Phase 1,",
          "New Delhi - 110 020.",
          "Tel : +91 11 2681 6545 / 2189 / 8012",
        ],
        contact: {
          name: "Mr. Gopender",
          email: "gopender@iigm.in",
          phone: "09313286528",
        },
      },
      {
        city: "Ludhiana",

        address: [
          "IIGM Private Limited",
          "SCO - 92, Urban Estate,",
          "Phase-1, Dugari,",
          "Ludhiana - 141 002.",
          "Tel : +91 161 249 9391",
        ],
        contact: {
          name: "Mr. Mool Chand",
          email: "mool.chand@iigm.in",
          phone: "9316914763",
        },
      },
      {
        city: "Kanpur",

        address: [
          "IIGM Private Limited",
          "119/941 A, Dharshanpurva,",
          "Kalpi Road,",
          "Kanpur - 208012,",
          "Uttar Pradesh.",
        ],
        contact: {
          name: "Mr. Ashish Mishra",
          email: "iigmkanpur@iigm.in",
          phone: "09369928931",
        },
      },
      {
        city: "Ahmedabad",

        address: [
          "Western India Marketing Co.",
          "Unit No. 416/418, 4th Floor",
          "Aakash Avenue",
          "Mithakhali Six Road,",
          "Navaranga Pura,",
          "Ahmedabad - 380 009.",
          "Tel: +917926462811 / 26462817",
          "Tel: +9130022817",
          "Tel: +9130083811 / 9374083311",
        ],
        contact: {
          name: "Mr.Rizwan Madhupurwala",
          email: "rizwan@westernindia.co.in",
          phone: "+91 8000191703",
        },
      },
      {
        city: "Surat",

        address: [
          "IIGM Private Limited",
          "Upper Ground Floor, Shop No.335 to 338,",
          "ArihantCcomplex, Near Udhna Bus Depot,",
          "Udhana Main Road, Udahna",
          "Surat-394210",
          "Tel: 0261-4016700",
        ],
        contact: { name: "Mr. Vipul Variya", email: "", phone: "9978408964" },
      },
      {
        city: "Indore",

        address: [
          "IIGM Private Limited",
          "Upper Ground Floor, Shop No.335 to 338,",
          "ArihantCcomplex, Near Udhna Bus Depot,",
          "Udhana Main Road, Udahna",
          "Surat-394210",
          "Tel: 0261-4016700",
        ],
        contact: { name: "Mr. Vipul Variya", email: "", phone: "9978408964" },
      },
    ],
    international: [
      {
        city: "Bangladesh",

        address: [
          "IIGM Private Limited",
          "House 97/A level 4,",
          "Road 25 Block A, Banani,",
          "Dhaka 1213, Bangladesh.",
          "Mobile: +880 18 4452 1001",
        ],
        contact: {
          name: "Mr.Chinmoy Mukherjee",
          email: "chinmoy.mukherjee@iigm.in",
          phone: "",
        },
      },
      {
        city: "Ethiopia",

        address: [
          "IIGM Private Limited",
          "Greenspan Ethiopia GB PLC",
          "House no 417, Kebele 02/03",
          "Kirkos sub city, PO Box : 102300",
          "Addis Ababa, Ethiopia.",
          "Mobile : +251 92 990 5491",
        ],
        contact: {
          name: "Mr. Vinay Aiyappa",
          email: "vinay.aiyappa@gsethiopia.iigm.in",
          phone: "",
        },
      },
      {
        city: "Jordan",

        address: [
          "IIGM Private Limited",
          "4th Floor,",
          "Omar Bin Khattab Commercial Complex,",
          "11, Elia Abu Maadi Street, Shmeisani",
          "Amman - 11134, Jordan.",
          "Phone: +962 7777 95022",
        ],
        contact: {
          name: "Mr. N.Venkatesh",
          email: "nvenkat@iigm.in",
          phone: "",
        },
      },
      {
        city: "Hong Kong",

        address: [
          "IIGM Private Limited",
          "Unit S 15/F One Midtown",
          "11 Hoi Shing Road,",
          "Tsuen Wan, New Territories",
          "Hong Kong SAR of P.R. China.",
          "Mobile: +91 9341264250",
        ],
        contact: {
          name: "Mr.Sajith Kumar",
          email: "sajith@iigm.in",
          phone: "",
        },
      },
    ],
  };

  return (
    <Fragment>
      <div className="breadcrumbs">
        <div className="crumb">Home</div>
        <div className="seperator">{">"}</div>
        <div className="crumb">Branches</div>
      </div>

      <div className="branches-page">
        <div className="page-title">Branches</div>
        <div className="page-desc">
          Our robust framework enables us to support leading edge technology and
          high volumes of production of our customers. With our pan India
          presence, we reach assistance and service reducing down-time
          significantly. Each local office stores equipment and parts with the
          knowhow intel required for you.
        </div>

        <div className="section">
          <div className="section-title">India</div>
          <div className="items">
            {branches.india.map((b) => (
              <Branch city={b.city} address={b.address} contact={b.contact} />
            ))}
          </div>
        </div>

        <div className="section">
          <div className="section-title">International</div>
          <div className="items">
            {branches.international.map((b) => (
              <Branch city={b.city} address={b.address} contact={b.contact} />
            ))}
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default Branches;

import React, { PureComponent, useEffect, useState } from "react";
import "react-virtualized/styles.css";
import {
  CellMeasurer,
  CellMeasurerCache,
  Grid,
  MultiGrid,
  AutoSizer,
} from "react-virtualized";
import { isMobile } from "react-device-detect";

// In this example, average cell width is assumed to be about 100px.
// This value will be used for the initial `Grid` layout.
// Cell measurements smaller than 75px should also be rounded up.
// Height is not dynamic.
const cache = new CellMeasurerCache({
  defaultHeight: 32,
  defaultWidth: isMobile ? 100 : 200,
  minHeight: 32,
  fixedWidth: true,
  fixedHeight: false,
});

const listNew = [
  [
    "Compare",
    "DDL-9000C-FDS",
    "DDL-9000C-FMS",
    "DDL-9000C-FSH",
    "DDL-9000C-SMS",
    "DDL-9000C-SSH",
    "Some other product",
  ],
  [
    "SKU",
    "G-S-Z-001",
    "G-S-Z-002",
    "G-S-Z-003",
    "G-S-Z-004",
    "G-S-Z-005",
    "A-B-C-005",
  ],
  [
    "Stitch system",
    "Standard zigzag",
    "Standard zigzag",
    "Standard zigzag",
    "Standard zigzag",
    "Standard zigzag",
    "Standard zigzag",
  ],
  [
    "Max sewing speed",
    "5,500 rpm",
    "5,500 rpm",
    "5,500 rpm",
    "5,500 rpm",
    "5,500 rpm",
    "5,500 rpm",
  ],
  ["Zigzag width", "5.0 mm", "5.0 mm", "5.0 mm", "5.0 mm", "5.0 mm", "5.0 mm"],
  ["Maximum feeding amount", "2.5", "2.5", "2.5", "2.5", "2.5", "2.5"],
  [
    "Lift of the presser foot (by hand)",
    "5.5 mm",
    "5.5 mm",
    "5.5 mm",
    "5.5 mm",
    "5.5 mm",
    "5.5 mm",
  ],
  [
    "Lift of the presser foot (by knee)",
    "5.5 mm",
    "5.5 mm",
    "5.5 mm",
    "5.5 mm",
    "5.5 mm",
    "5.5 mm",
  ],
  [
    "Thread take up",
    "Rotary Type",
    "Rotary Type",
    "Rotary Type",
    "Rotary Type",
    "Rotary Type",
    "Rotary Type",
  ],
  [
    "Lubrication",
    "Fully Automatic",
    "Fully Automatic",
    "Fully Automatic",
    "Fully Automatic",
    "Fully Automatic",
    "Fully Automatic",
  ],
  [
    "Lift of the presser foot (by knee)",
    "5.5 mm",
    "5.5 mm",
    "5.5 mm",
    "5.5 mm",
    "5.5 mm",
    "5.5 mm",
  ],
  [
    "Thread take up",
    "Rotary Type",
    "Rotary Type",
    "Rotary Type",
    "Rotary Type",
    "Rotary Type",
    "Rotary Type",
  ],
  [
    "Lubrication",
    "Fully Automatic",
    "Fully Automatic",
    "Fully Automatic",
    "Fully Automatic",
    "Fully Automatic",
    "Fully Automatic",
  ],
];

const list = [
  [
    "Compare models",
    "DDL-9000C-FDS",
    "DDL-9000C-FMS",
    "DDL-9000C-FSH",
    "DDL-9000C-SMS",
    "DDL-9000C-SSH",
  ],
  [
    "Type",
    "Premium Digital Type",
    "Premium Digital Type",
    "Premium Digital Type",
    "Digital Type",
    "Digital Type",
  ],
  [
    "Application",
    "Medium-weight",
    "Medium-weight",
    "Heavy-weight",
    "Medium-weight",
    "Heavy-weight",
  ],
  [
    "Lubrication",
    "Fully-dry head type",
    "Semi-dry",
    "Minute-quantity lubrication",
    "Semi-dry",
    "Minute-quantity lubrication",
  ],
  [
    "Max. Sewing Speed",
    "4,000sti/min＊",
    "5,000sti/min＊",
    "4,500sti/min＊",
    "5,000sti/min＊",
    "4,500sti/min＊",
  ],
  [
    "Hand Switch",
    "Provided as standard",
    "Provided as standard",
    "Provided as standard",
    "Provided as standard",
    "Provided as standard",
  ],
  ["Max. Stitch Length", "5mm", "5mm", "5mm", "5mm", "5mm"],
  [
    "Thread Trimming Method",
    "Double-edge rotary system",
    "Double-edge rotary system",
    "Double-edge rotary system",
    "Double-edge rotary system",
    "Double-edge rotary system",
  ],
  ["Needle Bar Stroke", "30.7mm", "30.7mm", "35mm", "30.7mm", "35mm"],
  ["Feed Dog Height", "0.8mm", "0.8mm", "1.2mm", "0.8mm", "1,2mm"],
  [
    "Needle Thread Transition",
    "Active tension",
    "Active tension",
    "Active tension",
    "Spring Type",
    "Spring type",
  ],
  [
    "Presser Foot Pressure",
    "Electronic control",
    "Electronic control",
    "Electronic control",
    "Spring Type",
    "Spring type",
  ],
  [
    "Lift of the Presser Foot",
    "By hand: 5.5mm, By knee: 15mm Auto: 1st stage 5.0 mm (*0.1~8.5mm), 2nd stage 8.5 mm (*8.5~13.5mm) *Adjustable on the operation panel",
    "By hand: 5.5mm, By knee: 15mm Auto: 1st stage 5.0 mm (*0.1~8.5mm), 2nd stage 8.5 mm (*8.5~13.5mm) *Adjustable on the operation panel",
    "By hand: 5.5mm, By knee: 15mm Auto: 1st stage 5.0 mm (*0.1~8.5mm), 2nd stage 8.5 mm (*8.5~13.5mm) *Adjustable on the operation panel",
    "By hand: 5.5mm, By knee: 15mm Auto: 1st stage 5.0 mm (*0.1~8.5mm), 2nd stage 8.5 mm (*8.5~13.5mm) *Adjustable on the operation panel",
    "By hand: 5.5mm, By knee: 15mm Auto: 1st stage 5.0 mm (*0.1~8.5mm), 2nd stage 8.5 mm (*8.5~13.5mm) *Adjustable on the operation panel",
  ],
  [
    "Needle",
    "DB×1・DB×5 (#11) #9~18 (Nm65~110)",
    "DB×1・DB×5 (#11) #9~18 (Nm65~110)",
    "DB×1・DB×5 (#11) #9~18 (Nm65~110)",
    "DB×1・DB×5 (#11) #9~18 (Nm65~110)",
    "DB×1・DB×5 (#11) #9~18 (Nm65~110)",
  ],
  [
    "Bobbin thread winder",
    "Built in the top of machine head (provided with the bobbin thread retaining plate)",
    "Built in the top of machine head (provided with the bobbin thread retaining plate)",
    "Built in the top of machine head (provided with the bobbin thread retaining plate)",
    "Built in the top of machine head (provided with the bobbin thread retaining plate)",
    "Built in the top of machine head (provided with the bobbin thread retaining plate)",
  ],
  [
    "Lubrication Oil",
    "JUKI New Defrix Oil No.1 or JUKI CORPORATION GENUINE OIL 7 (equivalent to ISO VG7)",
    "JUKI New Defrix Oil No.1 or JUKI CORPORATION GENUINE OIL 7 (equivalent to ISO VG7)",
    "JUKI New Defrix Oil No.1 or JUKI CORPORATION GENUINE OIL 7 (equivalent to ISO VG7)",
    "JUKI New Defrix Oil No.1 or JUKI CORPORATION GENUINE OIL 7 (equivalent to ISO VG7)",
    "JUKI New Defrix Oil No.1 or JUKI CORPORATION GENUINE OIL 7 (equivalent to ISO VG7)",
  ],
  [
    "Size of Bed",
    "517×178mm (distance from needle to machine arm：303mm)",
    "517×178mm (distance from needle to machine arm：303mm)",
    "517×178mm (distance from needle to machine arm：303mm)",
    "517×178mm (distance from needle to machine arm：303mm)",
    "517×178mm (distance from needle to machine arm：303mm)",
  ],
  [
    "Machine Head Drive",
    "Compact AC servomotor (400W) that is directly connected to the main shaft (direct-drive system)",
    "Compact AC servomotor (400W) that is directly connected to the main shaft (direct-drive system)",
    "Compact AC servomotor (400W) that is directly connected to the main shaft (direct-drive system)",
    "Compact AC servomotor (400W) that is directly connected to the main shaft (direct-drive system)",
    "Compact AC servomotor (400W) that is directly connected to the main shaft (direct-drive system)",
  ],
  ["Power Consumption", "520VA", "520VA", "520VA", "520VA", "520VA"],
  [
    "Net Weight",
    "Machine head: 40.5kg (with AK-154)",
    "Machine head: 40.5kg (with AK-154)",
    "Machine head: 40.5kg (with AK-154)",
    "Machine head: 40.5kg (with AK-154)",
    "Machine head: 40.5kg (with AK-154)",
  ],
];

function cellRenderer({ columnIndex, key, parent, rowIndex, style }) {
  const content = listNew[rowIndex]?.[columnIndex] || ""; // Derive this from your data somehow

  return (
    <CellMeasurer
      cache={cache}
      columnIndex={columnIndex}
      key={key}
      parent={parent}
      rowIndex={rowIndex}
    >
      {({ registerChild }) => (
        <div
          style={{
            ...style,
            whiteSpace: "wrap",
            padding: 16,
            fontSize: 12,
            overflow: "hidden",
            borderBottom: `solid ${!rowIndex ? 2 : 1}px whitesmoke`,
            borderRight: "solid 1px whitesmoke",
            ...(!rowIndex
              ? {
                  background: isMobile ? "whitesmoke" : "whitesmoke",
                  color: isMobile ? "black" : "black",
                }
              : {}),
          }}
        >
          {content}
          {rowIndex === 12 && <div style={{ height: "40px" }} />}
          {rowIndex === 13 && <div style={{ height: "8px" }} />}
          {rowIndex === 14 && <div style={{ height: "16px" }} />}
          {rowIndex === 15 && <div style={{ height: "16px" }} />}
          {rowIndex === 16 && <div style={{ height: "16px" }} />}
          {rowIndex === 17 && <div style={{ height: "16px" }} />}
        </div>
      )}
    </CellMeasurer>
  );
}

export default function MultiGridTable({ tableRef }) {
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    if (tableRef?.current) {
      setWidth(tableRef.current.offsetWidth);
      setHeight(tableRef.current.offsetHeight);
    }
  }, [tableRef]);

  if (!width || !height) {
    return <div />;
  }

  return (
    <MultiGrid
      columnCount={listNew[0].length}
      height={height}
      rowCount={listNew.length}
      rowHeight={cache.rowHeight}
      fixedColumnCount={1}
      fixedRowCount={1}
      width={width}
      columnWidth={isMobile ? 140 : 240}
      deferredMeasurementCache={cache}
      cellRenderer={cellRenderer}
      styleTopLeftGrid={{
        borderBottom: "2px solid whitesmoke",
        borderRight: "2px solid whitesmoke",
        fontWeight: "bold",
      }}
      styleTopRightGrid={{
        borderBottom: "2px solid whitesmoke",
        fontWeight: "bold",
      }}
      styleBottomLeftGrid={{
        borderRight: "2px solid whitesmoke",
      }}
    />
  );
}

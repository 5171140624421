import { useState, useRef, Fragment, useEffect } from "react";
import "../App.css";
import "../styles/Product.css";
import { ReactComponent as BrochureIcon } from "../icons/brochure.svg";
import { ReactComponent as CartIcon } from "../icons/cart.svg";
import { ReactComponent as CompareIcon } from "../icons/compare.svg";
import { ReactComponent as EnquireIcon } from "../icons/enquire.svg";
import { ReactComponent as HeartIcon } from "../icons/heart.svg";
import { ReactComponent as ShippingIcon } from "../icons/shipping.svg";
import { ReactComponent as WarrantyIcon } from "../icons/warranty.svg";
import { ReactComponent as UserIcon } from "../icons/user.svg";
import { ReactComponent as SearchIcon } from "../icons/search.svg";
import { ReactComponent as QuoteIcon } from "../icons/add-to-quote.svg";
import { ReactComponent as ShareIcon } from "../icons/share.svg";
import { ReactComponent as ShareIconGrey } from "../icons/share-grey.svg";
import { ReactComponent as RadioUncheckedIcon } from "../icons/radio-unchecked.svg";
import { ReactComponent as RadioCheckedIcon } from "../icons/radio-checked.svg";
import { ReactComponent as ChevronDownIcon } from "../icons/chevron-down.svg";
import { ReactComponent as ServiceIcon } from "../icons/service.svg";
import { ReactComponent as EmiIcon } from "../icons/emi.svg";
import { ReactComponent as StarIcon } from "../icons/star.svg";
import { ReactComponent as StarOutlineIcon } from "../icons/star-outline.svg";
import { ReactComponent as BadgeIcon } from "../icons/badge.svg";
import { ReactComponent as FactoryIcon } from "../icons/factory.svg";
import { ReactComponent as PlantIcon } from "../icons/plant.svg";
import { ReactComponent as PlusIcon } from "../icons/plus.svg";
import { ReactComponent as MinusIcon } from "../icons/minus.svg";
import { ReactComponent as PlayIcon } from "../icons/play.svg";
import { ReactComponent as DocumentIcon } from "../icons/document.svg";
import { ReactComponent as ViewIcon } from "../icons/view.svg";
import { ReactComponent as DownloadIcon } from "../icons/download.svg";
import { ReactComponent as VideoIcon } from "../icons/video.svg";
import { ReactComponent as ChevronRightIcon } from "../icons/chevron-right.svg";
import { ReactComponent as ChevronLeftIcon } from "../icons/chevron-left.svg";
// import { ReactComponent as UserIcon } from "../icons/user.svg";
import { isMobile } from "react-device-detect";
import { useAppState } from "../utils/appState";
import MultiGridTable from "../components/common/MultiGridTable";
import { useParams } from "react-router-dom";

function Badge({ icon, text, badgeIconStyle = {}, underline }) {
  return (
    <div className="badge">
      <div className="badge__icon" style={badgeIconStyle}>
        {icon}
      </div>
      <div className={"badge__description " + (underline ? "underlined" : "")}>
        {text}
      </div>
    </div>
  );
}

function ReviewList() {
  return (
    <div className="review">
      <div className="review__item">
        <div className="review__item__title">
          <div className="review__item__title__stars">
            <div className="review__item__title__stars__star">
              <StarIcon style={{ height: "20px" }} />
            </div>
            <div className="review__item__title__stars__star">
              <StarIcon style={{ height: "20px" }} />
            </div>
            <div className="review__item__title__stars__star">
              <StarIcon style={{ height: "20px" }} />
            </div>
            <div className="review__item__title__stars__star">
              <StarIcon style={{ height: "20px" }} />
            </div>
            <div className="review__item__title__stars__star">
              <StarOutlineIcon style={{ height: "20px" }} />
            </div>
          </div>
          <div className="review__item__title__text">Excellent Machine!</div>
        </div>

        <div className="review__item__content">
          This machine works great, is silent and gets the job done. Been using
          it for 2 years with no issues.
        </div>
        <div className="review__item__reviewer">
          <div className="review__item__reviewer__img">
            <UserIcon />
          </div>
          <div className="review__item__reviewer__name">Kushal Shah</div>
          <div className="review__item__timestamp">
            @ Jan 10th, 2019 6:00 PM
          </div>
        </div>
      </div>

      <div className="review__item">
        <div className="review__item__title">
          <div className="review__item__title__stars">
            <div className="review__item__title__stars__star">
              <StarIcon style={{ height: "20px" }} />
            </div>
            <div className="review__item__title__stars__star">
              <StarIcon style={{ height: "20px" }} />
            </div>
            <div className="review__item__title__stars__star">
              <StarIcon style={{ height: "20px" }} />
            </div>
            <div className="review__item__title__stars__star">
              <StarIcon style={{ height: "20px" }} />
            </div>
            <div className="review__item__title__stars__star">
              <StarOutlineIcon style={{ height: "20px" }} />
            </div>
          </div>
          <div className="review__item__title__text">Quick and Easy!</div>
        </div>
        <div className="review__item__content">
          This machine works great, is silent and gets the job done. Been using
          it for 2 years with no issues.
        </div>
        <div className="review__item__reviewer">
          <div className="review__item__reviewer__img">
            <UserIcon />
          </div>
          <div className="review__item__reviewer__name">John Smith</div>
          <div className="review__item__timestamp">
            @ Jan 10th, 2019 6:00 PM
          </div>
        </div>
      </div>
    </div>
  );
}

function UnorderedList() {
  return (
    <div className="highlights-tab">
      <div className="highlight-section-title">Subheading</div>
      <ul className="content-list">
        <li className="li">Productivity is dramatically increased</li>
        <li className="li">
          The machine demonstrates consistent trimming performance
        </li>
      </ul>

      <div className="highlight-section-title">Subheading</div>
      <ul className="content-list">
        <li className="li">Simplified mechanism for easy operation</li>
        <li className="li">
          Uneven feed of the upper and lower cloths is totally eliminated
        </li>
      </ul>
    </div>
  );
}

function SpecList() {
  return (
    <div className="spec-list">
      <div className="spec-list-item">
        <div className="key">Model name</div>
        <div className="value">LZ-2280N </div>
      </div>

      <div className="spec-list-item">
        <div className="key">SKU</div>
        <div className="value">G-S-Z-001 </div>
      </div>

      <div className="spec-list-item">
        <div className="key">Stitch system</div>
        <div className="value">Standard zigzag</div>
      </div>

      <div className="spec-list-item">
        <div className="key">Maximum sewing speed</div>
        <div className="value">5,500 rpm</div>
      </div>

      <div className="spec-list-item">
        <div className="key">Zigzag width</div>
        <div className="value">5.0 mm</div>
      </div>

      <div className="spec-list-item">
        <div className="key">Maximum feeding amount</div>
        <div className="value">2.5</div>
      </div>

      <div className="spec-list-item">
        <div className="key">Lift of the presser foot (by hand)</div>
        <div className="value">5.5 mm</div>
      </div>

      <div className="spec-list-item">
        <div className="key">Lift of the presser foot (by knee)</div>
        <div className="value">5.5 mm</div>
      </div>

      <div className="spec-list-item">
        <div className="key">Thread take up</div>
        <div className="value">Rotary Type</div>
      </div>

      <div className="spec-list-item">
        <div className="key">Lubrication</div>
        <div className="value">Fully Automatic</div>
      </div>
    </div>
  );
}

function EnquiryForm() {
  return (
    <div className="enquiry-form">
      <div className="form-section">
        <div className="row">
          <div className="form-group">
            <div className="label">Product Name</div>
            <input type="text" disabled={true} value={"LZ-2280N SERIES"} />
          </div>
        </div>

        <div className="row">
          <div className="form-group">
            <div className="label">Brand</div>
            <input type="text" disabled={true} value={"JUKI"} />
          </div>
        </div>

        <div className="row">
          <div className="form-group">
            <div className="label">Enquiry</div>
            <textarea></textarea>
          </div>
        </div>
      </div>
      <div className="form-section">
        <div className="title">Your Details</div>
        <div className="row">
          <div className="form-group">
            <div className="label">Name</div>
            <input type="text" />
          </div>
        </div>
        <div className="row two-column">
          <div className="form-group">
            <div className="label">Email</div>
            <input type="text" />
          </div>

          <div className="form-group">
            <div className="label">Phone</div>
            <input type="text" />
          </div>
        </div>

        <div className="row two-column">
          <div className="form-group">
            <div className="label">City</div>
            <input type="text" />
          </div>

          <div className="form-group">
            <div className="label">State</div>
            <input type="text" />
          </div>
        </div>
        <div className="row">
          <div className="form-group">
            <div className="label">Country</div>
            <input type="text" />
          </div>
        </div>
      </div>

      <div className="form-section">
        <div className="title">Organization Details</div>
        <div className="row">
          <div className="form-group">
            <div className="label">Organization Name</div>
            <input type="text" />
          </div>
        </div>

        <div className="row">
          <div className="form-group">
            <div className="label">Organization Address</div>
            <textarea></textarea>
          </div>
        </div>
      </div>

      <div className="primary-button">SUBMIT</div>
    </div>
  );
}

function ProductOverview() {
  return (
    <div className="blurb">
      <p>
        The sewing machine produces soft seams with consistency with a low
        tension applied to the thread even when it runs at a high speed.
      </p>

      <p className="bold">
        Some variant specific info here like a faster speed or lower cost or
        something like that.
      </p>

      <p>This product comes with:</p>
      <ul className="in-the-box">
        <li>1 Sewing Machine</li>
        <li>1 Motor</li>
        <li>1 Pedal</li>
        <li>2 of Some Thing</li>
      </ul>
    </div>
  );
}

const tabs = [
  { label: "Info", component: ProductOverview },
  { label: "Highlights", component: UnorderedList },
  { label: "Specs", component: SpecList },
  { label: "Reviews", component: ReviewList },
  { label: "Enquiry", component: EnquiryForm },
];

const bulkOrderCells = [
  { label: "Select", style: { width: "10%" } },
  { label: "Quantity", style: { width: "30%" } },
  { label: "Discount", style: { width: "30%" } },
  { label: "Price/piece", style: { width: "30%" } },
];

const bulkOrderRows = [
  [
    { lower: 2, upper: 2, label: "2" },
    { label: "1%" },
    { label: "₹ 9,900", value: 9900 },
  ],
  [
    { lower: 3, upper: 4, label: "3-4" },
    { label: "2%" },
    { label: "₹ 9,800", value: 9800 },
  ],
  [
    { lower: 5, upper: 9, label: "5-9" },
    { label: "3%" },
    { label: "₹ 9,700", value: 9700 },
  ],
  [
    { lower: 10, upper: false, label: "10+" },
    { label: "4%" },
    { label: "₹ 9,600", value: 9600 },
  ],
];

function Product({
  mediaRef,
  imageRef,
  galleryRef,
  scrollEndRef,
  mediaMargin = 0,
  galleryHeight = 600,
  bgScale,
}) {
  let { id } = useParams();
  const [, setAppState] = useAppState();
  const tabsRef = useRef(null);
  const tableRef = useRef(null);
  const [selectedTab, setSelectedTab] = useState(0);
  const [bulkOrderSelected, setBulkOrderSelected] = useState(false);
  const [selectedBulkOrderIndex, setSelectedBulkOrderIndex] = useState(0);
  const [deliveryPincode, setDeliveryPincode] = useState("");
  const [quantity, setQuantity] = useState(1);
  // const [mediaMargin, setMediaMargin] = useState(0);
  const TabContent = tabs[selectedTab].component;
  // const mediaRef = useRef(null);
  // const imageRef = useRef(null);
  // const scrollEndRef = useRef(null);

  // const handleScroll = (e) => {
  //   console.log("Handling scroll");
  //   if (isMobile) return;
  //   const offsetStart = mediaRef?.current.offsetTop;
  //   const offsetEnd =
  //     scrollEndRef.current.offsetTop - imageRef.current.clientHeight - 32;

  //   if (e.target.scrollTop < offsetStart) {
  //     setMediaMargin(0);
  //   } else if (e.target.scrollTop > offsetEnd) {
  //     setMediaMargin(offsetEnd - offsetStart);
  //   } else {
  //     setMediaMargin(e.target.scrollTop - mediaRef.current.offsetTop);
  //   }
  // };

  const determineBulkDiscount = (qty) => {
    const discountIndex = bulkOrderRows.findIndex((r) => {
      if (!r[0].upper) {
        return r[0].lower <= qty;
      } else {
        return r[0].lower <= qty && r[0].upper >= qty;
      }
    });

    if (discountIndex > -1) {
      setBulkOrderSelected(true);
      setSelectedBulkOrderIndex(discountIndex);
    } else {
      setBulkOrderSelected(false);
    }
  };
  console.log("PRODUCT ID:", id);

  return (
    <div className="product-page">
      <div className="breadcrumbs">
        <div className="crumb">Home</div>
        <div className="seperator">{">"}</div>
        <div className="crumb">Products</div>
        <div className="seperator">{">"}</div>
        <div className="crumb">LZ-2280N SERIES</div>
      </div>
      <div className="highlight-section">
        <div className="media" ref={mediaRef}>
          <div
            className="offset-scroll-container"
            style={{ transform: `translateY(${mediaMargin}px)` }}
          >
            {!isMobile && (
              <div
                className="gallery"
                ref={galleryRef}
                style={{ height: galleryHeight + "px" }}
              >
                <div className="gallery-item"></div>
                <div className="gallery-item"></div>
                <div className="gallery-item"></div>

                <div className="gallery-item"></div>
                <div className="gallery-item">
                  <PlayIcon style={{ height: "32px" }} />
                </div>
                <div className="gallery-item"></div>
                <div className="gallery-item"></div>
                <div className="gallery-item"></div>
                <div className="gallery-item"></div>
              </div>
            )}

            <div className="featured-image-container">
              <div className="featured-image" ref={imageRef}></div>
            </div>

            {isMobile && (
              <div className="gallery">
                <div className="gallery-item"></div>
                <div className="gallery-item"></div>
                <div className="gallery-item"></div>
                <div className="gallery-item"></div>
                <div className="gallery-item">
                  <PlayIcon style={{ height: "32px" }} />
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="meta">
          <div className="primary">
            <div className="category">
              Zig Zag Cutting Machine{" "}
              <div className="header-share-btn">
                <ShareIconGrey style={{ height: "16px", marginTop: "4px" }} />
              </div>
              <div className="stock-availability">IN STOCK</div>
            </div>
            <div className="primary-text">LZ-2280N SERIES </div>
            <div className="brand">
              <div className="brand-image">
                <img src={"/juki.png"} />
              </div>
              <div className="brand-name">JAPAN</div>
            </div>
          </div>

          <div className="variants">
            <div className="variants__item selected">LZ-2280N Variant 1</div>

            <div className="variants__item">LZ-2280N Ultra</div>

            <div className="variants__item disabled">LZ-2280N Mega</div>
          </div>

          {/* <div className="secondary">
            <div className="secondary-text">
              Availability: <span className="green">In Stock</span>
            </div>
            
          </div> */}
          <div className="tabbed-section" ref={tabsRef}>
            <div className="tabs">
              {tabs.map((t, i) => (
                <div
                  className={"tab " + (selectedTab === i ? "active" : "")}
                  onClick={() => {
                    setSelectedTab(i);
                  }}
                >
                  {t.label}
                </div>
              ))}
            </div>
            <div className="tabbed-section-content">
              <TabContent />
            </div>
          </div>
          {/* <div className="blurb">
          <p>
            The sewing machine produces soft seams with consistency with a low
            tension applied to the thread even when it runs at a high speed.
          </p>

          <p className="bold">
            Some variant specific info here like a faster speed or lower cost
            or something like that.
          </p>

          <p>This product comes with:</p>
          <ul className="in-the-box">
            <li>1 Sewing Machine</li>
            <li>1 Motor</li>
            <li>1 Pedal</li>
            <li>2 of Some Thing</li>
          </ul>
        </div> */}
          <div className="cart-options">
            <div className="price-quantity-container">
              <div className="price">
                {bulkOrderSelected && (
                  <div className="secondary-price">
                    <div className="primary-price__discount">
                      {bulkOrderRows[selectedBulkOrderIndex][1].label} OFF
                    </div>
                    <div className="text">{id === "2" ? "$" : "₹"} 10,000</div>
                  </div>
                )}
                <div className={"primary-price " + (id === "2" ? "usd" : "")}>
                  {bulkOrderSelected ? (
                    <Fragment>
                      <div className="text">
                        {bulkOrderRows[selectedBulkOrderIndex][2].label}{" "}
                        {/* <span className="subtext">+ TAXES</span> */}
                      </div>
                      <div className="price-metas">
                        {/* <div className="primary-price__discount">
                          {bulkOrderRows[selectedBulkOrderIndex][1].label} OFF
                        </div> */}
                        <div className="subtext">
                          +{" "}
                          <span
                            className="link"
                            onClick={() => {
                              setAppState({
                                modal: (
                                  <div className="modal-card">
                                    Taxes information here
                                  </div>
                                ),
                              });
                            }}
                          >
                            TAXES
                          </span>{" "}
                          {id === "2" && (
                            <Fragment>
                              {"& "}
                              <span
                                className="link"
                                onClick={() => {
                                  setAppState({
                                    modal: (
                                      <div className="modal-card">
                                        Import duties info here
                                      </div>
                                    ),
                                  });
                                }}
                              >
                                IMPORT DUTY
                              </span>
                            </Fragment>
                          )}
                        </div>
                      </div>
                    </Fragment>
                  ) : (
                    <Fragment>
                      <div className="text">
                        {id === "2" ? "$" : "₹"} 10,000{" "}
                        {/*<span className="subtext">+ TAXES</span>*/}
                      </div>
                      <div className="price-metas">
                        <div className="subtext">
                          +{" "}
                          <span
                            className="link"
                            onClick={() => {
                              setAppState({
                                modal: (
                                  <div className="modal-card">
                                    Taxes information here
                                  </div>
                                ),
                              });
                            }}
                          >
                            TAXES
                          </span>{" "}
                          {id === "2" && (
                            <Fragment>
                              {"& "}
                              <span
                                className="link"
                                onClick={() => {
                                  setAppState({
                                    modal: (
                                      <div className="modal-card">
                                        Import duties info here
                                      </div>
                                    ),
                                  });
                                }}
                              >
                                IMPORT DUTY
                              </span>
                            </Fragment>
                          )}
                        </div>
                      </div>
                    </Fragment>
                  )}
                </div>
              </div>
              {/* <div className="discount">Save 1%</div> */}
              <div className="quantity">
                <div className="picker">
                  <div
                    className="minus"
                    onClick={() => {
                      setQuantity((prev) => {
                        const newQuantity = Math.max(1, prev - 1);
                        determineBulkDiscount(newQuantity);
                        return newQuantity;
                      });
                    }}
                  >
                    <MinusIcon style={{ height: "14px" }} />
                  </div>
                  <div className="number">{quantity}</div>
                  <div
                    className="plus"
                    onClick={() => {
                      setQuantity((prev) => {
                        determineBulkDiscount(prev + 1);
                        return prev + 1;
                      });
                    }}
                  >
                    <PlusIcon style={{ height: "14px" }} />
                  </div>
                </div>
                <div className="unit">METRE</div>
              </div>
            </div>

            <div className="cart-options__buttons">
              <div className="secondary-button">
                <div className="icon">
                  <QuoteIcon
                    style={{
                      height: 16,
                      marginRight: 8,
                      marginTop: 4,
                      fill: "white",
                    }}
                  />
                </div>
                ADD TO QUOTE
              </div>

              <div className="primary-button">
                <div className="icon">
                  <CartIcon
                    style={{
                      height: 16,
                      marginRight: 8,
                      marginTop: 4,
                      fill: "white",
                    }}
                  />
                </div>
                ADD TO CART
              </div>
            </div>
          </div>

          <div className="bulk-order-card">
            <div className="bulk-order-card__header">
              Bulk Discounts Available!
              <ChevronDownIcon style={{ marginLeft: "auto" }} />
            </div>

            <div className="bulk-order-card__table">
              <div className="bulk-order-card__table__header">
                {bulkOrderCells.map((c) => (
                  <div
                    className="bulk-order-card__table__header__cell"
                    style={c.style}
                  >
                    {c.label}
                  </div>
                ))}
              </div>

              <div className="bulk-order-card__table__body">
                {bulkOrderRows.map((r, j) => (
                  <div className="bulk-order-card__table__body__row">
                    {bulkOrderCells.map((c, i) => (
                      <div
                        className={
                          "bulk-order-card__table__body__row__cell " +
                          (bulkOrderSelected && selectedBulkOrderIndex === j
                            ? "selected"
                            : "")
                        }
                        onClick={() => {
                          // console.log(r[0].lower);
                          if (bulkOrderSelected) {
                            if (selectedBulkOrderIndex === j) {
                              setBulkOrderSelected(false);
                            } else {
                              setSelectedBulkOrderIndex(j);
                              setQuantity(r[0].lower);
                            }
                          } else {
                            setBulkOrderSelected(true);
                            setSelectedBulkOrderIndex(j);
                            setQuantity(r[0].lower);
                          }
                        }}
                        style={c.style}
                      >
                        {!i ? (
                          <Fragment>
                            {bulkOrderSelected &&
                            selectedBulkOrderIndex === j ? (
                              <RadioCheckedIcon style={{ height: "14px" }} />
                            ) : (
                              <RadioUncheckedIcon style={{ height: "14px" }} />
                            )}
                          </Fragment>
                        ) : (
                          r[i - 1].label
                        )}
                      </div>
                    ))}
                  </div>
                ))}
              </div>

              <div
                className="bulk-order-card__table__footer hover-pointer"
                onClick={() => {
                  setAppState({
                    modal: (
                      <div className="modal-card">
                        Above max buld order quantity discount info here
                      </div>
                    ),
                  });
                }}
              >
                <div>Need more than 10 pieces? Get a customized quote</div>
              </div>
            </div>
          </div>

          <div className="delivery-availability">
            <div className="delivery-availability__title">
              <ShippingIcon style={{ marginRight: "8px", height: "18px" }} />
              Check Delivery & Pickup Options
            </div>

            <div className="delivery-availability__form">
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                }}
              >
                <input
                  type="text"
                  placeholder="Enter Pincode"
                  value={deliveryPincode}
                />

                <button type="submit" className="delivery-form-button">
                  GO
                </button>
              </form>
            </div>

            <div className="cart-options__free-shipping">
              Order 10+ units to qualify for free shipping on this order.
            </div>

            <div className="delivery-availability__result">
              Delivery to 560037 is available and usually takes 10-15 business
              days.
            </div>

            <div className="delivery-availability__result">
              There are{" "}
              <span
                className="link hover-pointer"
                onClick={() => {
                  setAppState({
                    modal: (
                      <div className="modal-card">Pickup locations here</div>
                    ),
                  });
                }}
              >
                6 pickup locations
              </span>{" "}
              available close to 560037.
            </div>
          </div>

          <div className="service-plan-card">
            <div className="service-plan-card__logo">
              <ServiceIcon style={{ height: isMobile ? "32px" : "64px" }} />
            </div>
            <div className="service-plan-card__content">
              <div className="service-plan-card__primary">
                {/* <div className="service-plan-card__primary__icon">
                  <ServiceIcon style={{ height: isMobile ? "32px" : "20px" }} />
                </div> */}
                WE SERVICE THIS PRODUCT!
              </div>

              {!isMobile && (
                <div className="service-plan-card__secondary">
                  At IIGM, we ensure your operations run seamlessly by
                  scheduling routine services and maintenance. You can focus on
                  your business, we handle the rest!
                </div>
              )}

              <div
                className="service-plan-card__link hover-pointer"
                onClick={() => {
                  setAppState({
                    modal: (
                      <div className="modal-card">Service plans info here</div>
                    ),
                  });
                }}
              >
                Check out our service plans
              </div>
            </div>
          </div>

          {/* <div className="ancillary-info">
            <div className="ancillary-info-list">
              <div className="ancillary-info-item">
                <div className="icon">
                  <BadgeIcon style={{ height: "24px", marginTop: 4 }} />
                </div>
                <div className="text">3 year manufacturer warranty</div>
              </div>
              <div className="ancillary-info-item">
                <div className="icon">
                  <EmiIcon style={{ height: 24, marginTop: 6 }} />
                </div>
                <div className="text">Low cost monthly EMI available</div>
              </div>
            </div>
            <div className="brand">
              <div className="brand-image">
                <img src={"/juki.png"} />
              </div>
              <div className="brand-name">Juki, Japan</div>
            </div>
          </div> */}

          {/* <div className="tabbed-section">
            <div className="tabs">
              {tabs.map((t, i) => (
                <div
                  className={"tab " + (selectedTab === i ? "active" : "")}
                  onClick={() => {
                    setSelectedTab(i);
                  }}
                >
                  {t.label}
                </div>
              ))}
            </div>
            <div className="tabbed-section-content">
              <TabContent />
            </div>
          </div> */}

          <div className="badges-container">
            <div
              className="badges-container__item"
              onClick={() => {
                setAppState({
                  modal: <div className="modal-card">Warranty info here</div>,
                });
              }}
            >
              <Badge
                icon={<BadgeIcon style={{ height: "56px" }} />}
                text={"12 MONTH MANUFACTURE WARRANTY"}
                underline={true}
              />
            </div>

            <div
              className="badges-container__item"
              onClick={() => {
                setAppState({
                  modal: (
                    <div className="modal-card">
                      Distributor guarantee info here
                    </div>
                  ),
                });
              }}
            >
              <Badge
                icon={<WarrantyIcon style={{ height: "40px" }} />}
                text={"6 MONTH IIGM DISTRIBUTOR WARRANTY"}
                underline={true}
              />
            </div>

            <div className="badges-container__item">
              <Badge
                icon={<FactoryIcon style={{ height: "48px" }} />}
                text={"DESIGNED IN JAPAN"}
              />
            </div>

            <div
              className="badges-container__item"
              onClick={() => {
                setAppState({
                  modal: <div className="modal-card">Servicing info here</div>,
                });
              }}
            >
              <Badge
                icon={<ServiceIcon style={{ height: "54px" }} />}
                text={"SERVICING GUARANTEE BY IIGM"}
                underline={true}
              />
            </div>

            <div
              className="badges-container__item"
              onClick={() => {
                setAppState({
                  modal: <div className="modal-card">EMI info here</div>,
                });
              }}
            >
              <Badge
                icon={<EmiIcon style={{ height: "50px" }} />}
                text={"LOW COST MONTHLY EMI AVAILABLE"}
                underline={true}
              />
            </div>

            <div className="badges-container__item">
              <Badge
                icon={<PlantIcon style={{ height: "50px" }} />}
                badgeIconStyle={{ background: "#ebfff4" }}
                text={"CERTIFIED ECO FRIENDLY"}
              />
            </div>
          </div>

          {/* <div className="delivery-availability">
            <div className="delivery-availability__title">
              <ShippingIcon style={{ marginRight: "8px", height: "18px" }} />
              Check Delivery & Pickup Options
            </div>

            <div className="delivery-availability__form">
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                }}
              >
                <input
                  type="text"
                  placeholder="Enter Pincode"
                  value={deliveryPincode}
                />

                <button type="submit" className="delivery-form-button">
                  GO
                </button>
              </form>
            </div>

            <div className="cart-options__free-shipping">
              Order 10+ units to qualify for free shipping on this order.
            </div>

            <div className="delivery-availability__result">
              Delivery to 560037 is available and usually takes 10-15 business
              days.
            </div>

            <div className="delivery-availability__result">
              There are <span className="link">6 pickup locations</span>{" "}
              available close to 560037.
            </div>
          </div> */}

          {/* <div className="secondary-options">
            <div className="secondary-option-item">
              <div className="icon">
                <ShareIcon />
              </div>
              <div className="text">SHARE</div>
            </div>
            <div className="secondary-option-item">
              <div className="icon">
                <BrochureIcon />
              </div>
              <div className="text">BROCHURE</div>
            </div>

            <div className="secondary-option-item">
              <div className="icon">
                <QuoteIcon />
              </div>
              <div className="text">ADD TO QUOTE</div>
            </div>

            <div className="secondary-option-item">
              <div className="icon">
                <CompareIcon />
              </div>
              <div className="text">COMPARE</div>
            </div>
          </div> */}

          <div className="cart-options">
            <div className="price-quantity-container">
              <div className="price">
                {bulkOrderSelected && (
                  <div className="secondary-price">
                    <div className="primary-price__discount">
                      {bulkOrderRows[selectedBulkOrderIndex][1].label} OFF
                    </div>
                    <div className="text">{id === "2" ? "$" : "₹"} 10,000</div>
                  </div>
                )}
                <div className={"primary-price " + (id === "2" ? "usd" : "")}>
                  {bulkOrderSelected ? (
                    <Fragment>
                      <div className="text">
                        {bulkOrderRows[selectedBulkOrderIndex][2].label}{" "}
                        {/* <span className="subtext">+ TAXES</span> */}
                      </div>
                      <div className="price-metas">
                        {/* <div className="primary-price__discount">
                          {bulkOrderRows[selectedBulkOrderIndex][1].label} OFF
                        </div> */}
                        <div className="subtext">
                          +{" "}
                          <span
                            className="link"
                            onClick={() => {
                              setAppState({
                                modal: (
                                  <div className="modal-card">
                                    Taxes information here
                                  </div>
                                ),
                              });
                            }}
                          >
                            TAXES
                          </span>{" "}
                          {id === "2" && (
                            <Fragment>
                              {"& "}
                              <span
                                className="link"
                                onClick={() => {
                                  setAppState({
                                    modal: (
                                      <div className="modal-card">
                                        Import duties info here
                                      </div>
                                    ),
                                  });
                                }}
                              >
                                IMPORT DUTY
                              </span>
                            </Fragment>
                          )}
                        </div>
                      </div>
                    </Fragment>
                  ) : (
                    <Fragment>
                      <div className="text">
                        {id === "2" ? "$" : "₹"} 10,000{" "}
                        {/*<span className="subtext">+ TAXES</span>*/}
                      </div>
                      <div className="price-metas">
                        <div className="subtext">
                          +{" "}
                          <span
                            className="link"
                            onClick={() => {
                              setAppState({
                                modal: (
                                  <div className="modal-card">
                                    Taxes information here
                                  </div>
                                ),
                              });
                            }}
                          >
                            TAXES
                          </span>{" "}
                          {id === "2" && (
                            <Fragment>
                              {"& "}
                              <span
                                className="link"
                                onClick={() => {
                                  setAppState({
                                    modal: (
                                      <div className="modal-card">
                                        Import duties info here
                                      </div>
                                    ),
                                  });
                                }}
                              >
                                IMPORT DUTY
                              </span>
                            </Fragment>
                          )}
                        </div>
                      </div>
                    </Fragment>
                  )}
                </div>
              </div>
              {/* <div className="discount">Save 1%</div> */}
              <div className="quantity">
                <div className="picker">
                  <div
                    className="minus"
                    onClick={() => {
                      setQuantity((prev) => {
                        const newQuantity = Math.max(1, prev - 1);
                        determineBulkDiscount(newQuantity);
                        return newQuantity;
                      });
                    }}
                  >
                    <MinusIcon style={{ height: "14px" }} />
                  </div>
                  <div className="number">{quantity}</div>
                  <div
                    className="plus"
                    onClick={() => {
                      setQuantity((prev) => {
                        determineBulkDiscount(prev + 1);
                        return prev + 1;
                      });
                    }}
                  >
                    <PlusIcon style={{ height: "14px" }} />
                  </div>
                </div>
                <div className="unit">METRE</div>
              </div>
            </div>

            <div
              className="cart-options__buttons"
              style={{ gridTemplateColumns: "100%" }}
            >
              {/* <div className="secondary-button">
                <div className="icon">
                  <QuoteIcon
                    style={{
                      height: 16,
                      marginRight: 8,
                      marginTop: 4,
                      fill: "white",
                    }}
                  />
                </div>
                ADD TO QUOTE
              </div> */}

              <div className="primary-button">
                <div className="icon">
                  <CartIcon
                    style={{
                      height: 16,
                      marginRight: 8,
                      marginTop: 4,
                      fill: "white",
                    }}
                  />
                </div>
                ADD TO CART
              </div>
            </div>
          </div>

          <div className="secondary-options">
            <div className="secondary-option-item hover-pointer">
              <div className="icon">
                <QuoteIcon />
              </div>
              <div className="text">Add to Quote</div>
            </div>
            <div className="secondary-option-item hover-pointer">
              <div className="icon">
                <ShareIcon />
              </div>
              <div className="text">Share</div>
            </div>
            <div className="secondary-option-item hover-pointer">
              <div className="icon">
                <BrochureIcon />
              </div>
              <div className="text">Brochure</div>
            </div>

            <div
              className="secondary-option-item hover-pointer"
              onClick={() => {
                setSelectedTab(4);
                tabsRef.current.scrollIntoView();
              }}
            >
              <div className="icon">
                <EnquireIcon />
              </div>
              <div className="text">Enquire</div>
            </div>
          </div>

          {id === "5" && (
            <div
              className="product-comparison-container__container"
              style={{ marginTop: "24px" }}
            >
              <div
                className="product-comparison-container__overlay__additional-resources"
                style={{
                  boxShadow: "none",
                  border: "none",
                  borderTop: "solid 1px whitesmoke",
                }}
              >
                <div
                  className="product-comparison-container__overlay__additional-resources__title"
                  style={{
                    padding: 0,
                    paddingTop: "16px",
                    paddingBottom: "16px",
                  }}
                >
                  <div className="product-comparison-container__overlay__additional-resources__title__icon">
                    <DocumentIcon
                      style={{
                        height: "16px",
                        marginTop: "4px",
                        marginLeft: "-8px",
                      }}
                    />
                  </div>
                  <div className="product-comparison-container__overlay__additional-resources__title__text">
                    ADDITIONAL RESOURCES
                  </div>
                </div>
                <div
                  className="product-comparison-container__overlay__additional-resources__list"
                  style={{ padding: 0, background: "white" }}
                >
                  <div
                    className="product-comparison-container__overlay__additional-resources__list__item"
                    style={{ border: "solid 1px whitesmoke" }}
                  >
                    <div className="product-comparison-container__overlay__additional-resources__list__item__img" />
                    <div className="product-comparison-container__overlay__additional-resources__list__item__details">
                      <div className="product-comparison-container__overlay__additional-resources__list__item__details__description">
                        <div className="product-comparison-container__overlay__additional-resources__list__item__details__description__primary">
                          Power Usage Comparison
                        </div>
                        <div className="product-comparison-container__overlay__additional-resources__list__item__details__description__secondary">
                          See how much power you can save with this machine with
                          this useful chart.
                        </div>
                      </div>
                      <div className="product-comparison-container__overlay__additional-resources__list__item__details__options">
                        <div className="product-comparison-container__overlay__additional-resources__list__item__details__options__option">
                          <ViewIcon
                            style={{ height: "16px" }}
                            className="icon"
                          />
                        </div>

                        <div className="product-comparison-container__overlay__additional-resources__list__item__details__options__option">
                          <DownloadIcon
                            style={{ height: "16px" }}
                            className="icon"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="product-comparison-container__overlay__additional-resources__list__item"
                    style={{ border: "solid 1px whitesmoke" }}
                  >
                    <div className="product-comparison-container__overlay__additional-resources__list__item__img" />
                    <div className="product-comparison-container__overlay__additional-resources__list__item__details">
                      <div className="product-comparison-container__overlay__additional-resources__list__item__details__description">
                        <div className="product-comparison-container__overlay__additional-resources__list__item__details__description__primary">
                          Some chart analysis
                        </div>
                        <div className="product-comparison-container__overlay__additional-resources__list__item__details__description__secondary">
                          See some useful information here regaring some useful
                          thing.
                        </div>
                      </div>
                      <div className="product-comparison-container__overlay__additional-resources__list__item__details__options">
                        <div className="product-comparison-container__overlay__additional-resources__list__item__details__options__option">
                          <ViewIcon
                            style={{ height: "16px" }}
                            className="icon"
                          />
                        </div>

                        <div className="product-comparison-container__overlay__additional-resources__list__item__details__options__option">
                          <DownloadIcon
                            style={{ height: "16px" }}
                            className="icon"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="product-comparison-container__overlay__additional-resources__list__item"
                    style={{ border: "solid 1px whitesmoke" }}
                  >
                    <div className="product-comparison-container__overlay__additional-resources__list__item__img" />
                    <div className="product-comparison-container__overlay__additional-resources__list__item__details">
                      <div className="product-comparison-container__overlay__additional-resources__list__item__details__description">
                        <div className="product-comparison-container__overlay__additional-resources__list__item__details__description__primary">
                          Some other resource
                        </div>
                        <div className="product-comparison-container__overlay__additional-resources__list__item__details__description__secondary">
                          A short description of some other custom resource can
                          go here.
                        </div>
                      </div>
                      <div className="product-comparison-container__overlay__additional-resources__list__item__details__options">
                        <div className="product-comparison-container__overlay__additional-resources__list__item__details__options__option">
                          <ViewIcon
                            style={{ height: "16px" }}
                            className="icon"
                          />
                        </div>

                        <div className="product-comparison-container__overlay__additional-resources__list__item__details__options__option">
                          <DownloadIcon
                            style={{ height: "16px" }}
                            className="icon"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {!isMobile && id !== "3" && id !== "5" && (
        <div className="product-comparison-container" ref={scrollEndRef}>
          <div className="product-comparison-container__overlay">
            {id !== "4" && (
              <div className="product-comparison-container__container">
                <div className="product-comparison-container__overlay__additional-resources">
                  <div className="product-comparison-container__overlay__additional-resources__title">
                    <div className="product-comparison-container__overlay__additional-resources__title__icon">
                      <DocumentIcon
                        style={{ height: "16px", marginTop: "4px" }}
                      />
                    </div>
                    <div className="product-comparison-container__overlay__additional-resources__title__text">
                      ADDITIONAL RESOURCES
                    </div>
                  </div>
                  <div className="product-comparison-container__overlay__additional-resources__list">
                    <div className="product-comparison-container__overlay__additional-resources__list__item">
                      <div className="product-comparison-container__overlay__additional-resources__list__item__img" />
                      <div className="product-comparison-container__overlay__additional-resources__list__item__details">
                        <div className="product-comparison-container__overlay__additional-resources__list__item__details__description">
                          <div className="product-comparison-container__overlay__additional-resources__list__item__details__description__primary">
                            Power Usage Comparison
                          </div>
                          <div className="product-comparison-container__overlay__additional-resources__list__item__details__description__secondary">
                            See how much power you can save with this machine
                            with this useful chart.
                          </div>
                        </div>
                        <div className="product-comparison-container__overlay__additional-resources__list__item__details__options">
                          <div className="product-comparison-container__overlay__additional-resources__list__item__details__options__option">
                            <ViewIcon
                              style={{ height: "16px" }}
                              className="icon"
                            />
                          </div>

                          <div className="product-comparison-container__overlay__additional-resources__list__item__details__options__option">
                            <DownloadIcon
                              style={{ height: "16px" }}
                              className="icon"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="product-comparison-container__overlay__additional-resources__list__item">
                      <div className="product-comparison-container__overlay__additional-resources__list__item__img" />
                      <div className="product-comparison-container__overlay__additional-resources__list__item__details">
                        <div className="product-comparison-container__overlay__additional-resources__list__item__details__description">
                          <div className="product-comparison-container__overlay__additional-resources__list__item__details__description__primary">
                            Some chart analysis
                          </div>
                          <div className="product-comparison-container__overlay__additional-resources__list__item__details__description__secondary">
                            See some useful information here regaring some
                            useful thing.
                          </div>
                        </div>
                        <div className="product-comparison-container__overlay__additional-resources__list__item__details__options">
                          <div className="product-comparison-container__overlay__additional-resources__list__item__details__options__option">
                            <ViewIcon
                              style={{ height: "16px" }}
                              className="icon"
                            />
                          </div>

                          <div className="product-comparison-container__overlay__additional-resources__list__item__details__options__option">
                            <DownloadIcon
                              style={{ height: "16px" }}
                              className="icon"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="product-comparison-container__overlay__additional-resources__list__item">
                      <div className="product-comparison-container__overlay__additional-resources__list__item__img" />
                      <div className="product-comparison-container__overlay__additional-resources__list__item__details">
                        <div className="product-comparison-container__overlay__additional-resources__list__item__details__description">
                          <div className="product-comparison-container__overlay__additional-resources__list__item__details__description__primary">
                            Some other resource
                          </div>
                          <div className="product-comparison-container__overlay__additional-resources__list__item__details__description__secondary">
                            A short description of some other custom resource
                            can go here.
                          </div>
                        </div>
                        <div className="product-comparison-container__overlay__additional-resources__list__item__details__options">
                          <div className="product-comparison-container__overlay__additional-resources__list__item__details__options__option">
                            <ViewIcon
                              style={{ height: "16px" }}
                              className="icon"
                            />
                          </div>

                          <div className="product-comparison-container__overlay__additional-resources__list__item__details__options__option">
                            <DownloadIcon
                              style={{ height: "16px" }}
                              className="icon"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div
              className="table"
              style={id === "4" ? { gridColumnStart: 1, gridColumnEnd: 3 } : {}}
              ref={tableRef}
            >
              <MultiGridTable
                tableRef={tableRef}
                height="500px"
                width="500px"
              />
            </div>
          </div>
          <div className="bg-image-wrapper">
            <img src={"/liquid-cheese.svg"} className="bg-image" style={{}} />
          </div>
        </div>
      )}

      {(id === "3" || id === "5") && (
        <div ref={scrollEndRef} style={{ marginTop: "64px" }} />
      )}

      {isMobile && id !== "3" && id !== "5" && (
        <div className="product-comparison-container" ref={scrollEndRef}>
          {id !== "4" && (
            <div className="product-comparison-container__overlay__additional-resources">
              <div className="product-comparison-container__overlay__additional-resources__title">
                <div className="product-comparison-container__overlay__additional-resources__title__icon">
                  <DocumentIcon style={{ height: "16px", marginTop: "4px" }} />
                </div>
                <div className="product-comparison-container__overlay__additional-resources__title__text">
                  ADDITIONAL RESOURCES
                </div>
              </div>
              <div className="product-comparison-container__overlay__additional-resources__list">
                <div className="product-comparison-container__overlay__additional-resources__list__item">
                  <div className="product-comparison-container__overlay__additional-resources__list__item__img" />
                  <div className="product-comparison-container__overlay__additional-resources__list__item__details">
                    <div className="product-comparison-container__overlay__additional-resources__list__item__details__description">
                      <div className="product-comparison-container__overlay__additional-resources__list__item__details__description__primary">
                        Power Usage Comparison
                      </div>
                      <div className="product-comparison-container__overlay__additional-resources__list__item__details__description__secondary">
                        See how much power you can save with this machine with
                        this useful chart.
                      </div>
                    </div>
                    <div className="product-comparison-container__overlay__additional-resources__list__item__details__options">
                      <div className="product-comparison-container__overlay__additional-resources__list__item__details__options__option">
                        <ViewIcon style={{ height: "16px" }} className="icon" />
                      </div>

                      <div className="product-comparison-container__overlay__additional-resources__list__item__details__options__option">
                        <DownloadIcon
                          style={{ height: "16px" }}
                          className="icon"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="product-comparison-container__overlay__additional-resources__list__item">
                  <div className="product-comparison-container__overlay__additional-resources__list__item__img" />
                  <div className="product-comparison-container__overlay__additional-resources__list__item__details">
                    <div className="product-comparison-container__overlay__additional-resources__list__item__details__description">
                      <div className="product-comparison-container__overlay__additional-resources__list__item__details__description__primary">
                        Some chart analysis
                      </div>
                      <div className="product-comparison-container__overlay__additional-resources__list__item__details__description__secondary">
                        See some useful information here regaring some useful
                        thing.
                      </div>
                    </div>
                    <div className="product-comparison-container__overlay__additional-resources__list__item__details__options">
                      <div className="product-comparison-container__overlay__additional-resources__list__item__details__options__option">
                        <ViewIcon style={{ height: "16px" }} className="icon" />
                      </div>

                      <div className="product-comparison-container__overlay__additional-resources__list__item__details__options__option">
                        <DownloadIcon
                          style={{ height: "16px" }}
                          className="icon"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="product-comparison-container__overlay__additional-resources__list__item">
                  <div className="product-comparison-container__overlay__additional-resources__list__item__img" />
                  <div className="product-comparison-container__overlay__additional-resources__list__item__details">
                    <div className="product-comparison-container__overlay__additional-resources__list__item__details__description">
                      <div className="product-comparison-container__overlay__additional-resources__list__item__details__description__primary">
                        Some other resource
                      </div>
                      <div className="product-comparison-container__overlay__additional-resources__list__item__details__description__secondary">
                        A short description of some other custom resource can go
                        here.
                      </div>
                    </div>
                    <div className="product-comparison-container__overlay__additional-resources__list__item__details__options">
                      <div className="product-comparison-container__overlay__additional-resources__list__item__details__options__option">
                        <ViewIcon style={{ height: "16px" }} className="icon" />
                      </div>

                      <div className="product-comparison-container__overlay__additional-resources__list__item__details__options__option">
                        <DownloadIcon
                          style={{ height: "16px" }}
                          className="icon"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="table" ref={tableRef}>
            <MultiGridTable tableRef={tableRef} />
          </div>
        </div>
      )}

      <div className="similar-products">
        <div className="sp-title">SIMILAR PRODUCTS</div>
        <div className="sp-list">
          <div className="sp-list-item">
            <div className="image"></div>
            <div className="details">
              <div className="text">
                <div className="name">Someproduct Name </div>
                <div className="price">₹10,000.00</div>
              </div>

              <div className="brand"></div>
            </div>
          </div>

          <div className="sp-list-item">
            <div className="image"></div>
            <div className="details">
              <div className="text">
                <div className="name">Someproduct Name </div>
                <div className="price">₹10,000.00</div>
              </div>

              <div className="brand"></div>
            </div>
          </div>

          <div className="sp-list-item">
            <div className="image"></div>
            <div className="details">
              <div className="text">
                <div className="name">Someproduct Name </div>
                <div className="price">₹10,000.00</div>
              </div>

              <div className="brand"></div>
            </div>
          </div>

          <div className="sp-list-item">
            <div className="image"></div>
            <div className="details">
              <div className="text">
                <div className="name">Someproduct Name </div>
                <div className="price">₹10,000.00</div>
              </div>

              <div className="brand"></div>
            </div>
          </div>

          <div className="sp-list-item">
            <div className="image"></div>
            <div className="details">
              <div className="text">
                <div className="name">Someproduct Name </div>
                <div className="price">₹10,000.00</div>
              </div>

              <div className="brand"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

// function ProductOld() {
//   const [selectedTab, setSelectedTab] = useState(0);
//   const [mediaMargin, setMediaMargin] = useState(0);
//   const TabContent = tabs[selectedTab].component;
//   const mediaRef = useRef(null);
//   const imageRef = useRef(null);
//   const scrollEndRef = useRef(null);

//   const handleScroll = (e) => {
//     if (isMobile) return;
//     const offsetStart = mediaRef?.current.offsetTop;
//     const offsetEnd =
//       scrollEndRef.current.offsetTop - imageRef.current.clientHeight - 32;

//     if (e.target.scrollTop < offsetStart) {
//       setMediaMargin(0);
//     } else if (e.target.scrollTop > offsetEnd) {
//       setMediaMargin(offsetEnd - offsetStart);
//     } else {
//       setMediaMargin(e.target.scrollTop - mediaRef.current.offsetTop);
//     }
//   };

//   return (
//     <div className="App" onScroll={handleScroll}>
//       <div className="header">
//         <div className="secondary-header">
//           <div className="link">Store Locator</div>
//           <div className="seperator">|</div>
//           <div className="link">Sign In Or Create An Account</div>
//         </div>
//         <div className="primary-header">
//           <div className="logo">
//             <img src="/iigm-logo.png" />
//           </div>
//           {!isMobile && (
//             <div className="options">
//               <div className="search">
//                 <input
//                   type="text"
//                   placeholder="Search by name, brand, category or keywords"
//                 />
//                 <div className="submit-button">
//                   <SearchIcon style={{ height: 20 }} />
//                 </div>
//               </div>
//               <div className="option-button">
//                 <div className="icon">
//                   <HeartIcon style={{ height: 20 }} />
//                 </div>
//                 <div className="text">
//                   <div className="primary">Wish List (0)</div>
//                 </div>
//               </div>
//               <div className="option-button">
//                 <div className="icon">
//                   <CartIcon style={{ fill: "#f60", height: 20 }} />
//                 </div>
//                 <div className="text">
//                   <div className="primary">Cart (0)</div>
//                 </div>
//               </div>
//               <div className="primary-button">
//                 <UserIcon style={{ marginRight: 8 }} />
//                 LOGIN
//               </div>
//             </div>
//           )}
//         </div>
//         <div className="nav">
//           {!isMobile && (
//             <Fragment>
//               <div className="nav-item">HOME</div>
//               <div className="nav-item">PRODUCTS</div>
//               <div className="nav-item">SPARE PARTS</div>
//               <div className="nav-item">BRANDS</div>
//               <div className="nav-item">SERVICES</div>
//               <div className="nav-item">ABOUT US</div>
//             </Fragment>
//           )}
//         </div>
//       </div>
//       <div className="content">
//         <div className="product-page">
//           <div className="breadcrumbs">
//             <div className="crumb">Home</div>
//             <div className="seperator">{">"}</div>
//             <div className="crumb">Products</div>
//             <div className="seperator">{">"}</div>
//             <div className="crumb">LZ-2280N SERIES</div>
//           </div>
//           <div className="highlight-section">
//             <div className="media" ref={mediaRef}>
//               <div
//                 className="offset-scroll-container"
//                 style={{ transform: `translateY(${mediaMargin}px)` }}
//               >
//                 {!isMobile && (
//                   <div className="gallery">
//                     <div className="gallery-item"></div>
//                     <div className="gallery-item"></div>
//                     <div className="gallery-item"></div>
//                     <div className="gallery-item"></div>
//                   </div>
//                 )}

//                 <div className="featured-image-container">
//                   <div className="featured-image" ref={imageRef}></div>
//                 </div>

//                 {isMobile && (
//                   <div className="gallery">
//                     <div className="gallery-item"></div>
//                     <div className="gallery-item"></div>
//                     <div className="gallery-item"></div>
//                     <div className="gallery-item"></div>
//                   </div>
//                 )}
//               </div>
//             </div>
//             <div className="meta">
//               <div className="primary">
//                 <div className="category">Zig Zag Cutting Machine</div>
//                 <div className="primary-text">LZ-2280N SERIES</div>
//               </div>

//               <div className="secondary">
//                 <div className="secondary-text">
//                   Availability: <span className="green">In Stock</span>
//                 </div>
//                 {/*<div className="secondary-text">
//                   SKU: <span className="bold">G-S-Z-001</span>
//                 </div>*/}
//               </div>
//               <div className="blurb">
//                 The sewing machine produces soft seams with consistency with a
//                 low tension applied to the thread even when it runs at a high
//                 speed.
//               </div>
//               <div className="cart-options">
//                 <div className="price-quantity-container">
//                   <div className="price">₹10,000.00</div>
//                   <div className="quantity">
//                     <div className="minus">-</div>
//                     <div className="number">1</div>
//                     <div className="plus">+</div>
//                   </div>
//                 </div>
//                 <div className="primary-button">
//                   <div className="icon">
//                     <CartIcon
//                       style={{
//                         height: 16,
//                         marginRight: 8,
//                         marginTop: 4,
//                         fill: "white",
//                       }}
//                     />
//                   </div>
//                   ADD TO CART
//                 </div>
//               </div>

//               <div className="ancillary-info">
//                 <div className="ancillary-info-list">
//                   <div className="ancillary-info-item">
//                     <div className="icon">
//                       <WarrantyIcon
//                         style={{ height: 20, marginLeft: 4, marginTop: 4 }}
//                       />
//                     </div>
//                     <div className="text">3 year manufacturer warranty</div>
//                   </div>
//                   <div className="ancillary-info-item">
//                     <div className="icon">
//                       <ShippingIcon style={{ height: 20, marginTop: 6 }} />
//                     </div>
//                     <div className="text">Delivery in 7-10 days</div>
//                   </div>
//                 </div>
//                 <div className="brand">
//                   <div className="brand-image">
//                     <img src={"/juki.png"} />
//                   </div>
//                   <div className="brand-name">Juki, Japan</div>
//                 </div>
//               </div>

//               <div className="tabbed-section">
//                 <div className="tabs">
//                   {tabs.map((t, i) => (
//                     <div
//                       className={"tab " + (selectedTab === i ? "active" : "")}
//                       onClick={() => {
//                         setSelectedTab(i);
//                       }}
//                     >
//                       {t.label}
//                     </div>
//                   ))}
//                 </div>
//                 <div className="tabbed-section-content">
//                   <TabContent />
//                 </div>
//               </div>

//               <div className="secondary-options">
//                 <div className="secondary-option-item">
//                   <div className="icon">
//                     <EnquireIcon />
//                   </div>
//                   <div className="text">ENQUIRE</div>
//                 </div>
//                 <div className="secondary-option-item">
//                   <div className="icon">
//                     <BrochureIcon />
//                   </div>
//                   <div className="text">BROCHURE</div>
//                 </div>

//                 <div className="secondary-option-item">
//                   <div className="icon">
//                     <HeartIcon />
//                   </div>
//                   <div className="text">WISHLIST</div>
//                 </div>

//                 <div className="secondary-option-item">
//                   <div className="icon">
//                     <CompareIcon />
//                   </div>
//                   <div className="text">COMPARE</div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//       <div className="similar-products" ref={scrollEndRef}>
//         <div className="sp-title">SIMILAR PRODUCTS</div>
//         <div className="sp-list">
//           <div className="sp-list-item">
//             <div className="image"></div>
//             <div className="details">
//               <div className="text">
//                 <div className="name">Someproduct Name </div>
//                 <div className="price">₹10,000.00</div>
//               </div>

//               <div className="brand"></div>
//             </div>
//           </div>

//           <div className="sp-list-item">
//             <div className="image"></div>
//             <div className="details">
//               <div className="text">
//                 <div className="name">Someproduct Name </div>
//                 <div className="price">₹10,000.00</div>
//               </div>

//               <div className="brand"></div>
//             </div>
//           </div>

//           <div className="sp-list-item">
//             <div className="image"></div>
//             <div className="details">
//               <div className="text">
//                 <div className="name">Someproduct Name </div>
//                 <div className="price">₹10,000.00</div>
//               </div>

//               <div className="brand"></div>
//             </div>
//           </div>

//           <div className="sp-list-item">
//             <div className="image"></div>
//             <div className="details">
//               <div className="text">
//                 <div className="name">Someproduct Name </div>
//                 <div className="price">₹10,000.00</div>
//               </div>

//               <div className="brand"></div>
//             </div>
//           </div>

//           <div className="sp-list-item">
//             <div className="image"></div>
//             <div className="details">
//               <div className="text">
//                 <div className="name">Someproduct Name </div>
//                 <div className="price">₹10,000.00</div>
//               </div>

//               <div className="brand"></div>
//             </div>
//           </div>
//         </div>
//       </div>
//       <div className="footer"></div>
//     </div>
//   );
// }

export default Product;

import { Fragment } from "react";
import "../../styles/Achievements.css";

function Achievement({ image, primary, secondary, desc, person }) {
  return (
    <div className="achievement">
      <div className="head">
        <div className="text">
          <div className="primary">{primary}</div>
          <div className="secondary">{secondary}</div>
        </div>
        <div className="image">
          <img src={image} />
        </div>
      </div>
      <div className="desc">{desc}</div>
      <div className="person">
        <div className="image"></div>
        <div className="text">
          <div className="name">{person.name}</div>
          <div className="designation">{person.designation}</div>
          <div className="organization">{person.organization}</div>
        </div>
      </div>
    </div>
  );
}

function Achievements() {
  const achievements = [
    {
      image: "/Gerber-tech.jpeg",
      primary: "Million Dollar Club",
      secondary: "1995, 2006, 2007, 2009, 2010, 2013, 2014",
      desc: "The international division of Gerber Technology had awarded IIGM the 'Million Dollar Club Award' for achieving over a million dollars in sales for multiple years in partnership. There are only a handful of agents in the world who can achieve this target year after year with diligence each time.",
      person: {
        name: "Pete Morrissey",
        designation: "VP, Global Sales & Service",
        organization: "Gerber Technology",
      },
    },
    {
      image: "/JUKI.jpeg",
      primary: "Award of Excellence",
      secondary: "2001, 2010, 2015",
      desc: "In recognition of our outstanding effort in achieving the yearly sales target for the country of India. On completing successful and mutually rewarding years with us, we bank on your continued association for many more years to come.",
      person: {
        name: "Akira Kiyohara",
        designation: "President and Director",
        organization: "Juki Corp.",
      },
    },
    {
      image: "/National-Award.jpeg",
      primary: "National Award for Fashion Industry",
      secondary: "1997",
      desc: "National Award for excellent apparel Technology in recognation of his outstanding contribution in Professional process manufacturing and information technology.Indigenisation and technology integration in the garment industry.",
      person: {
        name: "Vice President of India",
        designation: "",
        organization: "",
      },
    },
    {
      image: "/Wilcom.jpeg",
      primary: "20 Years of Partnership",
      secondary: "1995-2015",
      desc: "Wilcom international is proud to celebrate 20 years of partnership with IIGM in 2015, after they achieved agent of the year in FY-2013. Mr. Jonathan Wright, past executive president and Mr. Janos Horvath, current vice president are grateful for the long standing partnership.",
      person: {
        name: "Janos Horvath",
        designation: "VP, International Sales",
        organization: "Wilcom",
      },
    },
    {
      image: "/Jack.jpeg",
      primary: "3 Star Award & Strategic Distributor",
      secondary: "2015",
      desc: "China's number one sewing machine company has awarded us three stars for exceptional sales and service performance for medium and small customers in India. Our marketing and distributorship efforts were appreciated by the CEO of Jack.",
      person: {
        name: "Ruan",
        designation: "Chief Executive Officer",
        organization: "JACK Sewing Machine Co., Ltd.",
      },
    },
  ];
  return (
    <Fragment>
      <div className="breadcrumbs">
        <div className="crumb">Home</div>
        <div className="seperator">{">"}</div>
        <div className="crumb">Achievements</div>
      </div>

      <div className="achievements-page">
        <div className="page-title">Our Achievements</div>
        <div className="page-desc">
          Every equipment we install, every service we provide, every technology
          we offer, brings us a sense of achievement. Every step our customers
          take towards their goals we are filled with pride. Over the decades we
          have scaled many heights under the able leadership of our group and
          endlessly look for challenges to win over.
        </div>

        <div className="achievements">
          {achievements.map((a) => (
            <Achievement
              image={a.image}
              primary={a.primary}
              secondary={a.secondary}
              desc={a.desc}
              person={a.person}
            />
          ))}
        </div>
      </div>
    </Fragment>
  );
}

export default Achievements;

import { useState } from "react";
import "../styles/Home.css";
import { ReactComponent as EyeIcon } from "../icons/eye.svg";
import { ReactComponent as HeartIcon } from "../icons/heart.svg";
import { isMobile } from "react-device-detect";
import { Link } from "react-router-dom";

function Category({ image, desc }) {
  return (
    <div className="category">
      <div className="image">
        <img src={image} />
      </div>
      <div className="desc">{desc}</div>
    </div>
  );
}

function Brand({ image }) {
  return (
    <div className="brand">
      <div className="image">
        <img src={image} />
      </div>
    </div>
  );
}

function Service({ image, desc }) {
  return (
    <div className="service">
      <div className="image">
        <img src={image} />
      </div>
      <div className="desc">{desc}</div>
    </div>
  );
}

function Item({ image, title, name = "Product name", id = 1 }) {
  return (
    <Link to={"/product/" + id} className="popular-item">
      <div className="image">
        <img src={"/most-popular.png"} />
      </div>
      <div className="title">{name}</div>
      <div className="options">
        <div className="option">
          <EyeIcon className={"icon"} style={{ stroke: "#f60" }} /> Quick View
        </div>
        <div className="option" style={{ marginLeft: "auto" }}>
          <HeartIcon className={"icon"} /> Wishlist
        </div>
      </div>
    </Link>
  );
}

function Home() {
  return (
    <div className="home-page">
      <div className="hero">
        <div className="video">
          <video loop={true} autoPlay={true} muted={true}>
            <source src="hero-video.mp4" type="video/mp4" />
          </video>
        </div>
        <div className="overlay"></div>
        <div className="text">
          <div className="line">
            The World's {!isMobile && <span className="highlight">Best</span>}
          </div>
          {isMobile && (
            <div className="line">
              <span className="highlight">Best</span>
            </div>
          )}
          <div className="line">Garment Machines</div>
          <div className="subtext">
            Choose from 100+ brands and 10000+ parts
          </div>
          <div className="cta">Enquire Now</div>
        </div>
      </div>
      <div className="popular">
        <div className="head">
          <div className="section-title">POPULAR PRODUCTS</div>
          <div className="tabs">
            <div className="tab active">Garment Industry</div>
            <div className="tab">Infrastructure</div>
            <div className="tab">Laundry</div>
          </div>
        </div>
        <div className="products-list">
          {!isMobile && (
            <div className="most-popular">
              <div className="image">
                <img src={"/most-popular.png"} />
              </div>
              <div className="title">Product Name</div>
              <div className="desc">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque
                sit amet elementum sapien. Class aptent taciti sociosqu ad
                litora torquent per conubia nostra, per inceptos himenaeos.{" "}
              </div>
              <div className="cta">View Product</div>
              <div className="options">
                <div className="option">
                  <EyeIcon className={"icon"} style={{ stroke: "#f60" }} />{" "}
                  Quick View
                </div>
                <div className="option" style={{ marginLeft: "auto" }}>
                  <HeartIcon className={"icon"} /> Add to Wishlist
                </div>
              </div>
            </div>
          )}

          <div className="items">
            <Item name={"Product (INR)"} />
            <Item name={"Product (USD)"} id={2} />
            <Item name="No resources, no compare" id={3} />
            <Item name="Compare only" id={4} />
            <Item name="Resources only" id={5} />
            <Item />
            <Item />
            <Item />
          </div>
        </div>
      </div>
      <div className="services">
        <div className="section-title">OUR SERVICES</div>
        <div className="items">
          <Service image={"/Advisory.jpeg"} desc={"Project Advisory"} />
          <Service
            image={"/Sewing-Machine.jpeg"}
            desc={"Maintenance Services"}
          />
          <Service image={"TRaining.jpeg"} desc={"Training"} />
        </div>
      </div>
      <div className="brands">
        <div className="head">
          <div className="section-title">SHOP BY BRANDS</div>
        </div>
        <div className="items">
          <Brand image={"/eae.jpeg"} />
          <Brand image={"/Hashima.jpeg"} />
          <Brand image={"/barudan.jpeg"} />
          <Brand image={"/ishark-logo.jpeg"} />
          <Brand image={"/gerber-logo.jpeg"} />
          <Brand image={"/strobel.jpeg"} />
          <Brand image={"/yamato.jpeg"} />
          <Brand image={"/Jack-logo-51pix.jpeg"} />
          <Brand image={"/wilcom-logo.jpeg"} />
          <Brand image={"/tonello.jpeg"} />
          <Brand image={"/JUKI.jpeg"} />
          <Brand image={"/sprayway-logo2.jpeg"} />
        </div>
        <div className="view-all">See All Brands</div>
      </div>
      <div className="categories">
        <div className="section-title">SHOP BY CATEGORY</div>
        <div className="items">
          <div className="main-category">
            <div className="image">
              <img src={"/Advisory.jpeg"} />
            </div>
            <div className="desc">
              <div>Garment</div>
              <div>Industry</div>
            </div>
          </div>
          <div className="sub-categories">
            <Category desc={"Sewing"} image={"/sewing.png"} />
            <Category desc={"Cutting"} image={"/cutting.png"} />
            <Category desc={"Fusing"} image={"/fusing.png"} />
            <Category desc={"Needle Detection"} image={"/needle.png"} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;

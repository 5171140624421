import { useState } from "react";
import CartSummary from "../components/common/CartSummary";
import "../styles/Checkout.css";
import { ReactComponent as PlusIcon } from "../icons/plus.svg";
import { ReactComponent as CheckedIcon } from "../icons/checked.svg";
import { ReactComponent as UncheckedIcon } from "../icons/unchecked.svg";
import { isMobile } from "react-device-detect";

function MobileCheckoutForm() {
  const [step, setStep] = useState(0);

  const next = () => {
    setStep((prev) => {
      if (prev < 3) {
        return prev + 1;
      }
    });
  };

  const prev = () => {
    setStep((prev) => {
      if (prev > 0) {
        return prev - 1;
      }
    });
  };

  if (step === 1) {
    return <ShippingDetails next={next} prev={prev} />;
  }

  if (step === 2) {
    return <CompanyDetails next={next} prev={prev} />;
  }

  if (step === 3) {
    return <OrderSummary prev={prev} />;
  }

  return <PersonalDetails next={next} />;
}

function PersonalDetails({ next }) {
  return (
    <div className="checkout-page__section">
      <div className="checkout-page__section__title">
        <div className="checkout-page__section__title__index">STEP 1/3</div>{" "}
        PERSONAL DETAILS
      </div>
      <div className="checkout-page__section__body">
        <div className="form-group">
          <div className="input-group">
            <div className="input-group__label">
              First Name <span className="input-required-label">*</span>
            </div>
            <input type="text" className="input-group__input" />
          </div>

          <div className="input-group">
            <div className="input-group__label">
              Last Name <span className="input-required-label">*</span>
            </div>
            <input type="text" className="input-group__input" />
          </div>

          <div className="input-group">
            <div className="input-group__label">
              Email <span className="input-required-label">*</span>
            </div>
            <input type="text" className="input-group__input" />
          </div>

          <div className="input-group">
            <div className="input-group__label">
              Phone Number <span className="input-required-label">*</span>
            </div>
            <input type="text" className="input-group__input" />
          </div>
        </div>
      </div>

      <div className="checkout-page__section__options">
        <div className="checkout-page__section__payment-button" onClick={next}>
          CONTINUE
        </div>
      </div>
    </div>
  );
}

function ShippingDetails({ next, prev }) {
  const [billingSameAsShipping, setBillingSameAsShipping] = useState(true);
  return (
    <div className="checkout-page__section">
      <div className="checkout-page__section__title">
        <div className="checkout-page__section__title__index">STEP 2/3</div>{" "}
        SHIPPING DETAILS
      </div>

      <div className="checkout-page__section__body">
        <div className="form-group-section">
          <div className="checkout-page__section__body__title">
            Shipping Address
          </div>
          <div className="form-group">
            <div className="input-group">
              <div className="input-group__label">
                Address Line 1 <span className="input-required-label">*</span>
              </div>
              <input type="text" className="input-group__input" />
            </div>

            <div className="input-group">
              <div className="input-group__label">Address Line 2</div>
              <input type="text" className="input-group__input" />
            </div>

            <div className="input-group">
              <div className="input-group__label">
                City <span className="input-required-label">*</span>
              </div>
              <input type="text" className="input-group__input" />
            </div>

            <div className="input-group">
              <div className="input-group__label">
                State <span className="input-required-label">*</span>
              </div>
              <input type="text" className="input-group__input" />
            </div>

            <div className="input-group">
              <div className="input-group__label">
                Country <span className="input-required-label">*</span>
              </div>
              <input type="text" className="input-group__input" />
            </div>

            <div className="input-group">
              <div className="input-group__label">
                Zip/Postal Code <span className="input-required-label">*</span>
              </div>
              <input type="text" className="input-group__input" />
            </div>
          </div>
        </div>

        <div className="form-group-section">
          <div
            className="checkout-page__section__body__title link"
            onClick={() => {
              setBillingSameAsShipping((prev) => !prev);
            }}
          >
            {billingSameAsShipping ? (
              <CheckedIcon style={{ height: 14 }} />
            ) : (
              <UncheckedIcon style={{ height: 14 }} />
            )}
            Billing address is same as shipping address
          </div>
        </div>

        {!billingSameAsShipping && (
          <div className="form-group-section">
            <div className="checkout-page__section__body__title">
              Billing Address
            </div>
            <div className="form-group">
              <div className="input-group">
                <div className="input-group__label">
                  Address Line 1 <span className="input-required-label">*</span>
                </div>
                <input type="text" className="input-group__input" />
              </div>

              <div className="input-group">
                <div className="input-group__label">Address Line 2</div>
                <input type="text" className="input-group__input" />
              </div>

              <div className="input-group">
                <div className="input-group__label">
                  City <span className="input-required-label">*</span>
                </div>
                <input type="text" className="input-group__input" />
              </div>

              <div className="input-group">
                <div className="input-group__label">
                  State <span className="input-required-label">*</span>
                </div>
                <input type="text" className="input-group__input" />
              </div>

              <div className="input-group">
                <div className="input-group__label">
                  Country <span className="input-required-label">*</span>
                </div>
                <input type="text" className="input-group__input" />
              </div>

              <div className="input-group">
                <div className="input-group__label">
                  Zip/Postal Code{" "}
                  <span className="input-required-label">*</span>
                </div>
                <input type="text" className="input-group__input" />
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="checkout-page__section__options">
        <div className="checkout-page__section__payment-button" onClick={next}>
          CONTINUE
        </div>

        <div className="checkout-page__section__options__option" onClick={prev}>
          Back to Personal Details
        </div>
      </div>
    </div>
  );
}

function CompanyDetails({ next, prev }) {
  return (
    <div className="checkout-page__section">
      <div className="checkout-page__section__title">
        <div className="checkout-page__section__title__index">STEP 3/3</div>{" "}
        COMPANY DETAILS (OPTIONAL)
      </div>
      <div className="checkout-page__section__body">
        <div className="form-group">
          <div className="input-group">
            <div className="input-group__label">Company Name</div>
            <input type="text" className="input-group__input" />
          </div>

          <div className="input-group">
            <div className="input-group__label">GST Number</div>
            <input type="text" className="input-group__input" />
          </div>
        </div>
      </div>

      <div className="checkout-page__section__options">
        <div className="checkout-page__section__payment-button" onClick={next}>
          CONTINUE
        </div>

        <div className="checkout-page__section__options__option" onClick={prev}>
          Back to Shipping Details
        </div>
      </div>
    </div>
  );
}

function OrderSummary({ prev }) {
  return (
    <div className="checkout-page__order-summary">
      <div className="checkout-page__order-summary__title">ORDER SUMMARY</div>
      <CartSummary
        hideViewCart={true}
        buttonText="MAKE PAYMENT"
        includeShippingCosts={true}
      />
      <div className="checkout-page__section__options">
        <div
          className="checkout-page__section__options__option"
          onClick={prev}
          style={{ marginTop: 8 }}
        >
          Back to Company Details
        </div>
      </div>
    </div>
  );
}

export default function Checkout() {
  const [billingSameAsShipping, setBillingSameAsShipping] = useState(true);
  return (
    <div className="checkout-page">
      <div className="checkout-page__header">
        <div className="checkout-page__header__text">
          {isMobile ? "CHECKOUT" : "COMPLETE YOUR ORDER"}
        </div>
        {!isMobile && (
          <div className="checkout-page__header__payment-button">
            MAKE PAYMENT
          </div>
        )}
      </div>
      {isMobile ? (
        <MobileCheckoutForm />
      ) : (
        <div className="checkout-page__body">
          <div className="checkout-page__body__left-container">
            <div className="checkout-page__section">
              <div className="checkout-page__section__title">
                <span className="checkout-page__section__title__index">01</span>{" "}
                PERSONAL DETAILS
              </div>
              <div className="checkout-page__section__body">
                <div className="form-group">
                  <div className="input-group">
                    <div className="input-group__label">
                      First Name <span className="input-required-label">*</span>
                    </div>
                    <input type="text" className="input-group__input" />
                  </div>

                  <div className="input-group">
                    <div className="input-group__label">
                      Last Name <span className="input-required-label">*</span>
                    </div>
                    <input type="text" className="input-group__input" />
                  </div>

                  <div className="input-group">
                    <div className="input-group__label">
                      Email <span className="input-required-label">*</span>
                    </div>
                    <input type="text" className="input-group__input" />
                  </div>

                  <div className="input-group">
                    <div className="input-group__label">
                      Phone Number{" "}
                      <span className="input-required-label">*</span>
                    </div>
                    <input type="text" className="input-group__input" />
                  </div>
                </div>
              </div>
            </div>

            <div className="checkout-page__section">
              <div className="checkout-page__section__title">
                <span className="checkout-page__section__title__index">02</span>{" "}
                SHIPPING DETAILS
              </div>

              <div className="checkout-page__section__body">
                <div className="form-group-section">
                  <div className="checkout-page__section__body__title">
                    Shipping Address
                  </div>
                  <div className="form-group">
                    <div className="input-group">
                      <div className="input-group__label">
                        Address Line 1{" "}
                        <span className="input-required-label">*</span>
                      </div>
                      <input type="text" className="input-group__input" />
                    </div>

                    <div className="input-group">
                      <div className="input-group__label">Address Line 2</div>
                      <input type="text" className="input-group__input" />
                    </div>

                    <div className="input-group">
                      <div className="input-group__label">
                        City <span className="input-required-label">*</span>
                      </div>
                      <input type="text" className="input-group__input" />
                    </div>

                    <div className="input-group">
                      <div className="input-group__label">
                        State <span className="input-required-label">*</span>
                      </div>
                      <input type="text" className="input-group__input" />
                    </div>

                    <div className="input-group">
                      <div className="input-group__label">
                        Country <span className="input-required-label">*</span>
                      </div>
                      <input type="text" className="input-group__input" />
                    </div>

                    <div className="input-group">
                      <div className="input-group__label">
                        Zip/Postal Code{" "}
                        <span className="input-required-label">*</span>
                      </div>
                      <input type="text" className="input-group__input" />
                    </div>
                  </div>
                </div>

                <div className="form-group-section">
                  <div
                    className="checkout-page__section__body__title link"
                    onClick={() => {
                      setBillingSameAsShipping((prev) => !prev);
                    }}
                  >
                    {billingSameAsShipping ? (
                      <CheckedIcon style={{ height: 14 }} />
                    ) : (
                      <UncheckedIcon style={{ height: 14 }} />
                    )}
                    Billing address is same as shipping address
                  </div>
                </div>

                {!billingSameAsShipping && (
                  <div className="form-group-section">
                    <div className="checkout-page__section__body__title">
                      Billing Address
                    </div>
                    <div className="form-group">
                      <div className="input-group">
                        <div className="input-group__label">
                          Address Line 1{" "}
                          <span className="input-required-label">*</span>
                        </div>
                        <input type="text" className="input-group__input" />
                      </div>

                      <div className="input-group">
                        <div className="input-group__label">Address Line 2</div>
                        <input type="text" className="input-group__input" />
                      </div>

                      <div className="input-group">
                        <div className="input-group__label">
                          City <span className="input-required-label">*</span>
                        </div>
                        <input type="text" className="input-group__input" />
                      </div>

                      <div className="input-group">
                        <div className="input-group__label">
                          State <span className="input-required-label">*</span>{" "}
                        </div>
                        <input type="text" className="input-group__input" />
                      </div>

                      <div className="input-group">
                        <div className="input-group__label">
                          Country{" "}
                          <span className="input-required-label">*</span>
                        </div>
                        <input type="text" className="input-group__input" />
                      </div>

                      <div className="input-group">
                        <div className="input-group__label">
                          Zip/Postal Code{" "}
                          <span className="input-required-label">*</span>
                        </div>
                        <input type="text" className="input-group__input" />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="checkout-page__section">
              <div className="checkout-page__section__title">
                <span className="checkout-page__section__title__index">03</span>{" "}
                COMPANY DETAILS
              </div>
              <div className="checkout-page__section__body">
                <div className="form-group">
                  <div className="input-group">
                    <div className="input-group__label">Company Name</div>
                    <input type="text" className="input-group__input" />
                  </div>

                  <div className="input-group">
                    <div className="input-group__label">GST Number</div>
                    <input type="text" className="input-group__input" />
                  </div>
                </div>
              </div>
            </div>

            {!isMobile && (
              <div className="checkout-page__section__payment-button">
                MAKE PAYMENT
              </div>
            )}
          </div>
          <div className="checkout-page__body__right-container">
            <div className="checkout-page__order-summary">
              <div className="checkout-page__order-summary__title">
                ORDER SUMMARY
              </div>
              <CartSummary
                hideViewCart={true}
                buttonText="MAKE PAYMENT"
                includeShippingCosts={true}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
